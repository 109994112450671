import { useState, useEffect, useRef, memo } from 'react';
// import { Icon } from '@plone/volto/components';
import { Marker, Popup } from 'react-map-gl/maplibre';
// import beben_standard from '../../icons/beben/location-pin-icon.svg';
import CircleMarker from './CircleMarker';
import { useDispatch } from 'react-redux';
import { getGeospheredataplatformdata } from '../../../actions';
// import styles from '../Messstation/TawesMarker.module.css';
import moment from 'moment';
import BebenMarkersDiv from './BebenMarkerDiv';
moment.locale('de');

const iconSize = (el) => {
  const magnitude = el.reference_magnitude;

  const mag = magnitude ? magnitude[0] : 0;

  let size;
  if (mag < 0.5) {
    size = 12;
  } else if (mag < 4) {
    size = Math.round((mag - 0.5) * 4) + 12; //11 ( )
  } else {
    size = Math.round((mag - 4) * 6) + 26;
  }
  return size;
};
const iconColorDate = (el) => {
  const date = moment(el.datetime_utc);
  const today = moment().utc();

  const diffDays = today.diff(date, 'days');
  if (diffDays >= 0 && diffDays < 1) {
    return '#3300b9';
  } else if (diffDays >= 1 && diffDays < 3) {
    return '#8b9af3';
  } else if (diffDays >= 3) {
    return '#d1e7fc';
  }

  return 'transparent'; // Default fallback
  // el.properties.mag*20+'px'
};

//location can be 1,2,3
// const filterdDataF = (location, inputData) => {
//   if (!Array.isArray(inputData)) return;

//   let locationid = location === 0 ? 1 : location === 1 ? 2 : 3;
//   const tempData = inputData.filter((i) => i.location_id <= locationid);

//   return tempData;
// };

const BebenMarkers = memo(
  ({ filteredMarkers, locationid, parentData, language }) => {
    const dispatch = useDispatch();

    const [showPopup, setShowPopup] = useState({});

    const popupRef = useRef();

    const setTooltip = (e) => {
      e.owner = 'beben';
      dispatch({
        type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
        payload: e,
      });
      // window.location.hash = `tab=Beben${e.event_id}`;
      dispatch(
        getGeospheredataplatformdata(
          parentData.serviceaddress,
          parentData.shakemap,
          {
            id: e.event_id,
          },
          ['id'],
        ),
      );

      popupRef.current = e;
      setShowPopup(e);
    };

    const handleKeyDown = (e, showTooltip) => {
      if (e.key === 'Enter') {
        setTooltip(showTooltip);
      } else if (e.key === 'Escape') {
        setShowPopup(false);
      }
    };

    useEffect(() => {
      dispatch({
        type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
        payload: null,
        owner: 'beben', //data.varation,
      });
    }, []);

    const tooltopReducer = () => {
      setShowPopup(false);
      // dispatch({
      //   type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
      //   payload: null,
      //   owner: 'beben', //data.varation,
      // });
    };
    const markerRef = useRef(null);

    return (
      <>
        {filteredMarkers.length > 0 &&
          filteredMarkers.map((el, i) => (
            <div
              key={i}
              ref={markerRef}
              // onMouseEnter={() => handleMouseEnter(el)}
            >
              <Marker
                longitude={el.lon}
                latitude={el.lat}
                key={i}
                onClick={() => setTooltip(el)}
              >
                <CircleMarker
                  size={iconSize(el)}
                  // color={el.event_type === 'fe' ? 'darkred' : 'red'}
                  borderColor={el.event_type === 'fe' ? '#bfce40' : '#00000066'}
                  border={el.event_type === 'fe' ? '3px' : '1px'}
                  color={iconColorDate(el)}
                  // borderRadius={el.event_type === 'fe' ? '20%' : '50%'}
                />
              </Marker>
            </div>
          ))}
        {Object.keys(showPopup).length > 0 && (
          <div ref={popupRef}>
            <Popup
              longitude={showPopup.lon}
              latitude={showPopup.lat}
              anchor="bottom"
              closeOnClick={false}
              onClose={() => {
                tooltopReducer();
              }}
            >
              <BebenMarkersDiv
                handleKeyDown={handleKeyDown}
                setTooltipF={setTooltip}
                showPopup={showPopup}
                language={language}
              />
            </Popup>
          </div>
        )}
      </>
    );
  },
);

export default BebenMarkers;
//
