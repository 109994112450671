// WeatherTable.jsx
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styles from './WeatherforecastChartTable.module.css';
import moment from 'moment';

// import Map_Warning_White from '../../icons/austria/Map_Warning_White_16px .svg';
import Drop_Weather_Midnight_16px from '../../../icons/austria/Drop_Weather_Midnight_16px.svg';
import { Icon } from '@plone/volto/components';
import WeatherForecastHelperSvgDay from '../../../constants/WeatherForecastHelperSvgDay';
import {
  mappingSymbToText,
  mappingSymbToTextEnglisch,
} from '../../../constants/WeatherforecastHelper';
import { getGeospheredataplatformdata } from '../../../actions';
import WeatherforecastTableWarnIcon from './WeatherforecastTableWarnIcon';
import isMobileDevice from '../HelperFunctions/isMobile';
// moment.locale('de');
const WeatherforecastChartTable = ({
  checkedDaily,
  data,
  point_id,
  language,
}) => {
  const dispatch = useDispatch();
  const indicatorRef = useRef(null);
  useEffect(() => {
    const indicator = indicatorRef.current;
    if (!isMobileDevice()) return;
    // Add animation class once
    if (indicator) {
      // Scroll back to the start after a delay
      const timeout1 = setTimeout(() => {
        indicator.scrollTo({ left: 100, behavior: 'smooth' });
      }, 500); // Wait 1 second before scrolling back
      // Scroll back to the start after a delay
      const timeout2 = setTimeout(() => {
        indicator.scrollTo({ left: 0, behavior: 'smooth' });
      }, 1200); // Wait 1 second before scrolling back

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      }; // Cleanup timeout on unmount
    }
  }, []);
  useEffect(() => {
    if (!checkedDaily) {
      dispatch(
        getGeospheredataplatformdata(
          data.serviceaddress,
          data.servicename,
          {
            id: point_id,
          },
          ['id'],
        ),
      );
    }
  }, [checkedDaily]);

  return (
    <>
      {checkedDaily && (
        <div className={styles.parentTableDiv} ref={indicatorRef}>
          <table className={styles.table} aria-label="Wettervorhersage">
            <thead>
              <tr>
                {checkedDaily.properties.forecast.map((item, index) => (
                  <th key={index}>
                    <div
                      className={styles.thFlex}
                      aria-label={moment(item.time, 'YYYY-MM-DD')
                        // .add(index, 'day')
                        .format('dddd')}
                    >
                      {moment(item.time, 'YYYY-MM-DD')
                        // .add(index, 'day')
                        .format('dddd')}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {checkedDaily.properties.forecast.map((item, index) => (
                  <td
                    aria-label={moment(item.time, 'YYYY-MM-DD').format('dddd')}
                    key={index}
                  >
                    <div className={styles.weatherSymbol}>
                      {/* 🌧️ {item.symbol} */}
                      {item.symbol ? (
                        <Icon
                          name={WeatherForecastHelperSvgDay[item.symbol]}
                          size="60px"
                          title={
                            language == 'de'
                              ? mappingSymbToText[item.symbol]
                              : mappingSymbToTextEnglisch[item.symbol]
                          }
                        />
                      ) : (
                        ''
                      )}
                      <div
                        className={styles.marginTop5}
                        aria-label={`Tagestemperatur Maximum: ${item.low} Grad, Minimum: ${item.high} Grad, Tagesniederschlag:${item.rr} Millimeter`}
                      >
                        <div
                          className={styles.temperature}
                          aria-label={`Temperatur Maximum: ${item.low} Grad, Minimum: ${item.high} Grad`}
                        >
                          {' '}
                          {language === 'de'
                            ? Number.isInteger(item.high)
                              ? item.high
                              : item.high.toFixed(1).replace('.', ',')
                            : item.high.toFixed(1)}
                          °<div className={styles.slash}> | </div>{' '}
                          {language === 'de'
                            ? Number.isInteger(item.low)
                              ? item.low
                              : item.low.toFixed(1).replace('.', ',')
                            : item.low.toFixed(1)}
                          °
                        </div>
                        {/* {item.precipitation} */}
                        <div className={styles.flexRRSymb}>
                          <Icon
                            name={Drop_Weather_Midnight_16px}
                            size="14px"
                            title="Niederschlagicon"
                          />
                          <div className={styles.rrMargin}>
                            {language === 'de'
                              ? Number.isInteger(item.rr)
                                ? item.rr
                                : item.rr.toFixed(1).replace('.', ',')
                              : item.rr}{' '}
                            mm
                          </div>

                          <WeatherforecastTableWarnIcon
                            warning={item.warning}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default WeatherforecastChartTable;
