import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetObject, setComponent } from '../actions';
import { getHash } from '../components/utils/hashingHelpers';

const useHashChange = (memoizedData, location) => {
  const [mapClick, setmapClick] = useState('map');
  const dispatch = useDispatch();

  const handleHashChange = useCallback(() => {
    const point_id = getHash('point_id');
    const tab = getHash('tab');

    if (
      memoizedData.variation === 'beben' ||
      memoizedData.variation === 'tawes'
    ) {
      if (tab === 'map' || tab === '') {
        setmapClick('map');
      } else if (tab === 'tablemode') {
        setmapClick('table');
        // console.log('table');
      }
    }

    if (memoizedData.variation === 'weatherforecast') {
      if (tab === 'map' || tab === '') {
        dispatch({
          type: 'CHART_COMPONENT',
          serviceName: memoizedData.variation + 'CHART',
          flag: false,
        });
        dispatch({ type: 'SET_CLICK_X_AXIS', payload: false });
        dispatch(resetObject(memoizedData.highcharts_grid)); // warum brauch ich das alles?
      } else if (tab === 'tablemode') {
        dispatch({
          type: 'CHART_COMPONENT',
          serviceName: memoizedData.variation + 'CHART',
          flag: memoizedData.variation + 'TABLE',
        });
      } else if (!isNaN(parseInt(point_id)) && tab == 'chart') {
        //existiert die point_id?
        dispatch(
          setComponent(
            memoizedData.variation + 'CHART',
            memoizedData.variation + 'CHART',
            null,
            null,
            point_id,
          ),
        );
      }
    }
    return [mapClick, setmapClick];
  }, [memoizedData.variation, dispatch]);

  useEffect(() => {
    handleHashChange();
  }, [location]);

  return [mapClick, setmapClick];
};

export default useHashChange;
