export const setHash = (key, value) => {
  const currentHash = window.location.hash.slice(1);
  const params = new URLSearchParams(currentHash);

  if (value === null || value === undefined) {
    params.delete(key);
  } else {
    params.set(key, value);
  }

  window.location.hash = params.toString();
};

export const getHash = (key) => {
  const hash = window.location.hash.slice(1);
  const params = new URLSearchParams(hash);
  return params.get(key);
};
