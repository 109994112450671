import React, { useEffect, useState } from 'react';
import styles from './WeatherForecastText.module.css'; // Import CSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '../ui/Accordion';
import { getGeospheredataplatformdata } from '../../../actions';
import { forecastTextMapping } from '../../../constants/WeatherforecastHelper';

const WeatherForecastText = ({ forecasttext, selName }) => {
  const alltexts = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata[forecasttext]
      : {},
  );
  const [showText, setShowText] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 5000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   dispatch(getGeospheredataplatformdata(serviceaddress, forecasttext));
  // }, []);
  const selectStation = Object.values(forecastTextMapping)[selName];
  const selNameLng = Object.keys(forecastTextMapping)[selName];
  return (
    <div className={styles.weatherInfo}>
      {selectStation && alltexts?.data ? (
        <Accordion
          items={alltexts?.data.filter((i) => i.stationid == selectStation)}
          columns={alltexts?.data.some(
            (i) => i.stationid == selectStation && i.textmarken_id == 615,
          )}
          titleAllg={['Wetterprognose', 'Bergprognose']}
          region={selNameLng}
        />
      ) : (
        <>
          {showText && (
            <p>
              Achtung! Aufgrund von Problemen, werden derzeit keine
              Prognosetexte angezeigt!!!{' '}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default WeatherForecastText;
