import React from 'react';
import WarnWidget from './Warnings/WarnWidget'; // Adjust the import path as needed

interface WarningBlockProps {
  data: {
    variation: string;
    warnstatus: string;
    warnseite: string;
  };
}

const WarningBlock: React.FC<WarningBlockProps> = React.memo(({ data }) => {
  return (
    <div className="block geosphere_interactive_map mt-bottom">
      {/* <InfoComponent text="This is some information text." /> */}
      <WarnWidget data_warnstatus={data.warnstatus} warnpage={data.warnseite} />
    </div>
  );
});

export default WarningBlock;
