import React from 'react';
import ButtonNoHtmlatt from '../ui/ButtonNoHtmlatt';
import cx from 'classnames';
interface VariationNavButtonsProps {
  data: {
    variation: string;
  };
  language: string;
  markerdata?: {
    data: unknown;
  };
  mapClick?: string;
  FsetTableMode?: (mode: string) => void;
}

const VariationNavButtons: React.FC<VariationNavButtonsProps> = React.memo(
  ({ data, language, markerdata, mapClick, FsetTableMode }) => {
    const renderButtonGroup = (
      isMapActive: boolean,
      tableModeHandler?: () => void,
    ) => (
      <div className="table_mode weatherbutton">
        <ButtonNoHtmlatt
          variant="primary"
          active={isMapActive ? 'true' : undefined}
          onClick={isMapActive ? null : tableModeHandler}
          label={language === 'de' ? 'Kartenansicht' : 'Map View'}
        />
        {markerdata?.data && (
          <ButtonNoHtmlatt
            variant="primary"
            active={isMapActive ? undefined : 'true'}
            onClick={isMapActive ? tableModeHandler : null}
            label={language === 'de' ? 'Tabellenansicht' : 'Table View'}
          />
        )}
      </div>
    );

    switch (data.variation) {
      case 'weatherforecast':
        const goTo = mapClick === 'map' ? 'tablemode' : 'map';
        const booleanMaporTable = mapClick === 'map' ? true : false;
        return renderButtonGroup(booleanMaporTable, () =>
          FsetTableMode?.(goTo),
        );

      case 'tawes':
        return (
          <div className={cx('messstationTabellediv')}>
            <ButtonNoHtmlatt
              variant="primary"
              active={mapClick === 'map'}
              onClick={() => FsetTableMode('map')}
              label={language === 'de' ? 'Messstationen' : 'Map View'}
            />
            {markerdata?.data && (
              <ButtonNoHtmlatt
                variant="primary"
                active={mapClick === 'table'}
                onClick={() => FsetTableMode('tablemode')}
                label={language === 'de' ? 'Tabellenansicht' : 'Table View'}
              />
            )}
          </div>
        );
      case 'beben':
        return (
          <div
            className={cx('messstationTabellediv', {
              borderBottomGreen: mapClick === 'table',
            })}
            style={{ width: 'auto' }}
          >
            <div className={cx('messstationTabellediv')}>
              <ButtonNoHtmlatt
                variant="primary"
                active={mapClick === 'map'}
                onClick={() => FsetTableMode('map')}
                label={language === 'de' ? 'Kartenansicht' : 'Map View'}
              />
              {markerdata?.data && (
                <ButtonNoHtmlatt
                  variant="primary"
                  active={mapClick === 'table'}
                  onClick={() => FsetTableMode('tablemode')}
                  label={language === 'de' ? 'Tabellenansicht' : 'Table View'}
                />
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  },
);

export default VariationNavButtons;
