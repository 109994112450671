import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import styles from './WarnWidget.module.scss'; // Import the SCSS module
import moment from 'moment';

import cx from 'classnames';
import ExternalLink from '../ui/ExternalLink';
import InformationStatus from '../ui/InformationStatus';
import {
  warnstufeToText,
  warnstufeToTextEnglish,
} from '../../utils/warnstufeToText';
moment.locale('de');
function getDayOffsets(events) {
  if (!events) return;
  return events.map((event) => {
    const startDate = moment.unix(event.start);
    const endDate = moment.unix(event.end);
    if (startDate.isSame(endDate, 'day')) {
      return; // Same day
    }
    const dayDifference = endDate
      .startOf('day')
      .diff(startDate.startOf('day'), 'days'); // endDate.diff(startDate, 'days');
    const dayOffsets = Array.from({ length: dayDifference + 1 }, (_, i) => {
      return startDate
        .clone()
        .add(i, 'days')
        .diff(moment().startOf('day'), 'days');
    });

    return {
      ...event,
      dayOffsets,
    };
  });
}

const getDayDifference = (timestamp) => {
  // Get the start of today for reference when the warning start
  const today = moment().startOf('day');
  const date = moment.unix(timestamp);
  return date.diff(today, 'days');
};

const WarnWidget = memo(({ data_warnstatus, warnpage }) => {
  const warnstatus = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata[data_warnstatus]
      : {},
  );
  const getGewitterAuto = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata['getGewitterAuto']
      : {},
  );

  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );

  const nowUtc = moment.utc();

  const filteredFeatures = useMemo(() => {
    if (!warnstatus?.data?.features) return [];
    return warnstatus.data.features
      .map((feature) => feature.properties)
      .filter((feature) => moment.unix(feature.end).isAfter(nowUtc));
  }, [warnstatus, nowUtc]);

  const groupedFeatures = useMemo(() => {
    const grouped = {};

    const updateGroup = (dayIndex, wlevel) => {
      if (!grouped[dayIndex]) {
        grouped[dayIndex] = { maxWlevel: 0, hasWarnings: false };
      }
      if (wlevel > grouped[dayIndex].maxWlevel) {
        grouped[dayIndex].maxWlevel = wlevel;
        grouped[dayIndex].hasWarnings = true;
      }
    };

    filteredFeatures.forEach((feature) => {
      const dayIndex = getDayDifference(feature.start);

      updateGroup(dayIndex, feature.wlevel);
    });

    getDayOffsets(filteredFeatures)
      .filter(Boolean)
      .forEach((element) => {
        element.dayOffsets.forEach((dayIndex) =>
          updateGroup(dayIndex, element.wlevel),
        );
      });

    return grouped;
  }, [filteredFeatures]);

  const wlevelColor = (wlevel) => {
    switch (wlevel) {
      case 1:
        return styles.bar1;
      case 2:
        return styles.bar2;
      case 3:
        return styles.bar3;
      default:
        return styles.bar; // Fallback to the default 'bar' class
    }
  };

  const getNextNDays = (n, language) => {
    if (!groupedFeatures) return;
    const today = new Date(); // Get today's date
    const dayNames = ['Heute', 'Morgen', 'Übermorgen']; // Names for today, tomorrow, and the day after tomorrow
    const dayNameE = ['Today', 'Tomorrow'];
    const dates = [];

    for (let i = 0; i < n; i++) {
      const date = new Date(today); // Create a new date object for each day
      date.setDate(today.getDate() + i); // Set the date to the next day

      if (i === 0) {
        if (
          groupedFeatures[i]?.maxWlevel === 0 &&
          getGewitterAuto.data?.features[0]
        ) {
          groupedFeatures[i].maxWlevel = 1;
        }
        dates.push({
          name: language == 'de' ? dayNames[i] : dayNameE[i],
          date,
          shortName: dayNames[i],
          wlevel: groupedFeatures[i]?.maxWlevel,
        });
      } else if (i < 2) {
        dates.push({
          name: language == 'de' ? dayNames[i] : dayNameE[i],
          date,
          shortName:
            language == 'de'
              ? date
                  .toLocaleDateString('de-DE', { weekday: 'long' })
                  .slice(0, 2)
              : date
                  .toLocaleDateString('en-EN', { weekday: 'long' })
                  .slice(0, 2),
          wlevel: groupedFeatures[i]?.maxWlevel,
        });
      } else {
        dates.push({
          name:
            language == 'de'
              ? date.toLocaleDateString('de-DE', { weekday: 'long' })
              : date.toLocaleDateString('en-EN', { weekday: 'long' }),
          shortName:
            language == 'de'
              ? date
                  .toLocaleDateString('de-DE', { weekday: 'long' })
                  .slice(0, 2)
              : date
                  .toLocaleDateString('en-EN', { weekday: 'long' })
                  .slice(0, 2),
          date,
          wlevel: groupedFeatures[i]?.maxWlevel,
        }); // Push the date and its name into the array
      }
    }

    return dates;
  };
  const nextDays = useMemo(() => getNextNDays(5, language), [groupedFeatures]);

  const externalLinkText =
    language == 'de'
      ? `Hier finden Sie detaillierte Information zu Wetterwarnungen`
      : 'For more detailed information on weather warnings';

  // return <p>test</p>;
  const lastmodified = moment().unix();
  return warnstatus?.data ? (
    <div className={styles.backgroundWidget}>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <p className={styles.pTagBig}>
            {language == 'de' ? 'Wetterwarnung' : 'Weather warning'}
          </p>
          <p className={styles.text}>
            {language == 'de'
              ? `Die Karte zeigt die aktuelle Warnsituation für den heutigen Tag. Die
            Gemeinden sind entsprechend der jeweils höchsten Warnstufe
            eingefärbt. In der Trendleiste ist jedem Tag die österreichweit
            höchste Warnfarbe zugeordnet.`
              : ` The map shows the current warning situation for today. The municipalities are coloured according to the highest warning level. 
              In the trend bar, the highest warning colour for Austria is assigned to each day.`}
          </p>
          {/* <p className={styles.title}> Warnungsübersicht</p> */}
          <div className={styles.barsContainer}>
            {/* cx(
            'block',
            'geosphere_interactive_map', */}
            <div className={styles.barsRow}>
              {nextDays.map((selectableDay, index) => {
                return (
                  <div key={index} className={styles.flexRow}>
                    <span className={styles.marginTop}>
                      {selectableDay.name}
                    </span>
                    <div
                      className={cx(
                        styles.bar,
                        wlevelColor(selectableDay.wlevel),
                      )}
                    ></div>
                    <span>
                      {selectableDay.wlevel > 0 && (
                        <p>
                          {language == 'de' ? 'Warnstufe ' : 'Warnlevel '}{' '}
                          {language == 'de'
                            ? warnstufeToText(selectableDay.wlevel)
                            : warnstufeToTextEnglish(selectableDay.wlevel)}{' '}
                        </p>
                      )}
                      {!selectableDay.wlevel && (
                        <p>
                          {language == 'de' ? 'Keine Warnung' : 'No Warning '}
                        </p>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.offsetExternalLink}>
          <ExternalLink text={externalLinkText} url={warnpage} />
        </div>
        <div className={styles.rightContent}>
          <img
            src={`https://warnungen.zamg.at/geospheregfx/de/heute/alle/at/?t=${lastmodified}`}
            alt="Bild zeigt aktuelle Warnungen für heute"
            className={styles.image}
            title="Bild zeigt aktuelle Warnungen für heute"
          />
        </div>
      </div>
    </div>
  ) : (
    <InformationStatus language={language} outputtext="Warnungen" />
  );
});

export default WarnWidget;
