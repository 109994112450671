import React from 'react';
import WeatherForecastInfo from './Forecast/WeatherForecastInfo';
import InfoComponent from './ui/InfoComponent';

interface WeatherForecastInfoBlockProps {
  datavariation: string;
  datainformationText: string;
  datainformationLink: string;
  language: string;
  isToday: string; // null wenn keine daten da sind
  markerdataExists: boolean;
  markerdataError: Errorobject;
}
interface Errorobject {
  status?: number;
}

const WeatherForecastInfoBlock: React.FC<WeatherForecastInfoBlockProps> =
  React.memo(
    ({
      language,
      isToday,
      markerdataExists,
      datavariation,
      datainformationText,
      datainformationLink,
      markerdataError,
    }) => {
      return (
        <>
          {(datavariation === 'weatherforecast' || markerdataExists) && (
            <WeatherForecastInfo
              today={datavariation === 'weatherforecast' ? isToday : undefined}
              language={language}
              status={markerdataError?.status ?? 200}
              // status --- > like that in case of undefined set default value
            />
          )}
          {/* EMD error information! */}
          {datavariation !== 'warning' &&
          !markerdataExists &&
          datainformationText ? (
            <InfoComponent
              informationText={datainformationText}
              informationLink={datainformationLink}
              language={language}
            />
          ) : null}
        </>
      );
    },
  );

export default WeatherForecastInfoBlock;
