import styledata from './style.json';

export const SimpleMapSchema = {
  title: 'SimpleMap',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'latitude',
        'longitude',
        'zoom',
        'minzoom',
        'maxzoom',
        'maxBounds',
        'serviceaddress',
        'servicename',
        'forcastdays',
        'forecastPoints',
        'informationLink',
        'informationText',
      ],
    },
    // {
    //   id: 'warnbar',
    //   title: 'warnbar',
    //   fields: ['warnbar'],
    // },
    {
      id: 'highcharts',
      title: 'forecast',
      fields: ['highcharts_grid', 'textsforecast'],
    },
    {
      id: 'shakemap',
      title: 'shakemap overlay',
      fields: ['shakemap'],
    },
    {
      id: 'Warnungen',
      title: 'warnungen',
      fields: ['warnseite', 'warnaddress', 'warnname', 'warnstatus'],
    },
    {
      id: 'style',
      title: 'Style overlays gis',
      fields: ['styleaddress'],
    },
    // {
    //   id: 'layers',
    //   title: 'Layers',
    //   fields: ['maplayers'],
    // },
  ],
  properties: {
    latitude: {
      title: 'latitude',
      type: 'number',
      default: 47.394,
    },
    longitude: {
      title: 'longitude',
      type: 'number',
      default: 13.687,
      missing_value: 13.687,
    },
    zoom: {
      title: 'zoom',
      default: 6,
    },
    minzoom: {
      title: 'minzoom',
      default: 3,
      missing_value: 3,
    },
    maxzoom: {
      title: 'maxzoom',
      default: 10,
      missing_value: 10,
    },
    maxBounds: {
      title: 'maxBounds',
      // type: 'array',
      // default: [
      //   [5.770472586910756, 42.91867190052025],
      //   [23.364053565449552, 52.46728647599588],
      // ],
    },

    serviceaddress: {
      title: 'Address of service',
    },
    servicename: {
      title: 'Name of service',
    },
    informationLink: {
      title: 'informationLink',
    },
    informationText: {
      title: 'Informations Text',
    },
    warnseite: {
      title: 'Warnseite',
      default: 'https://warnungen.zamg.at/',
    },
    warnaddress: {
      title: 'Address of waraddress',
      default: 'https://warnungen.zamg.at/wsapp/api/',
    },
    warnname: {
      title: 'warmcoordinates',
      default: 'getWarningsForCoords',
    },
    warnstatus: {
      title: 'Status/Widget',
      // default: 'getWarnstatus',
    },
    textsforecast: {
      title: 'Address of textsforecast',
      type: 'text',

      // choices: [
      //   ['getTextprognose', 'active textsforecast'],
      //   ['', 'no active textsforecast'],
      // ],
    },
    forcastdays: {
      title: 'forcastdays',
      // type: 'array',
      default: 8,
    },
    forecastPoints: {
      title: 'forecastPoints',
    },

    styleaddress: {
      title: 'Address of style',
      type: 'text',
      default:
        'https://tiles.arcgis.com/tiles/Q50VQz37ImhG4P49/arcgis/rest/services/Hintergrundkarte_mit_Relief_von_Europa/MapServer/tile/{z}/{y}/{x}',
      choices: [
        [styledata, 'gis.geosphere.at_basemap_grau_und_ortsnamen'],
        [
          'https://gis.geosphere.at/portal/sharing/rest/content/items/291da5eab3a0412593b66d384379f89f/resources/styles/root.json?f=pjson',
          'gis.geosphere.at_basemap_grau',
        ],
        // [
        //   'https://gis.geosphere.at/portal/sharing/rest/content/items/1768e8369a214dfab4e2167d5c5f2454/resources/styles/root.json?f=pjson',
        //   'gis.geosphere.at_basemap_ortsnamen',
        // ],
        [
          'https://tiles.arcgis.com/tiles/Q50VQz37ImhG4P49/arcgis/rest/services/Hintergrundkarte_mit_Relief_von_Europa/MapServer/tile/{z}/{y}/{x}',
          'ForecastMap',
        ],
        [
          // 'https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tilemap/{z}/{y}/{x}',
          'localestyling.json', // liegt lokal - quasi dummy - SimpleMap - Zeile 282 - mapStyle -> holt sich ...styledata
          'whole world1 local',
        ],
        [
          // https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tilemap/2/0/0/32/32?f=json
          // https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tilemap/9/176/272/8/8?f=json
          // https://services.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}
          'https://services.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}',
          'whole world2',
        ],
      ],
    },

    // warnbar: {
    //   title: 'warnbar',
    //   type: 'text',
    //   default: '',
    //   choices: [
    //     ['warning', 'active Warnbar'],
    //     ['', 'no active Warnbar'],
    //   ],
    // },
    shakemap: {
      title: 'shakemaps',
      type: 'text',
      default: '',
      choices: [
        ['shakemap', 'active shakemap'],
        ['', 'no active shakemap'],
      ],
    },
    highcharts_grid: {
      title: 'highcharts flex',
      type: 'text',
    },

    // not sure. every layer has an own styling.
    // maybe make a big styling array of objects with the relevant styling
    //  than it could work. but ofcours we must integrate the possibility
    //  to include an array of objects and add them to he map
    //  write now we have to layers, i know more to come

    // maplayers: {
    //   title: 'Map Layers',
    //   type: 'array',
    //   creatable: true,
    //   choices: [
    //     ['', 'no Layer'],
    //     ['greenAT', 'greenLayer'],
    //     ['shakemap', 'active shakemap'],
    //   ],
    // },
  },
  required: [],
};
