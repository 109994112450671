import React, { useRef, useEffect } from 'react';
import styles from './BebenDetailView.module.scss';
import moment from 'moment';
import { pointOrComma } from '../../utils/pointOrComma';
moment.locale('de');

function BebenDetailView({
  clickElement,
  language,
  // imageUrl = 'https://www.zamg.ac.at/zamgWeb/geophysik/vbebenkarte_xml/quakes/evid53167056/wf_evid53167056.png',
}) {
  const refScrollto = useRef(null);
  useEffect(() => {
    if (refScrollto.current) {
      refScrollto.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [clickElement]);
  return (
    <>
      <div className={styles.container} ref={refScrollto}>
        <div className={styles.tableContainer}>
          <p> {clickElement.data.maptitle}</p>
          <table className={styles.infoTable}>
            <tbody>
              <tr>
                <th>{language == 'de' ? 'Datum:' : 'Date: '}</th>
                <td>
                  {moment(clickElement.data.datetime_utc).format('DD.MM.YYYY')}
                </td>
              </tr>
              <tr>
                <th>
                  {language == 'de' ? 'Herdzeit(UTC):' : 'Origin time(UTC):'}
                </th>
                <td>
                  {language == 'de'
                    ? moment(clickElement.data.datetime_utc).format(
                        'HH:mm:ss,S',
                      )
                    : moment(clickElement.data.datetime_utc).format(
                        'HH:mm:ss.S',
                      )}{' '}
                </td>
              </tr>
              <tr>
                <th>
                  {language == 'de' ? 'Herdzeit' : 'Origin time'}(
                  {clickElement.data.timezone}):
                </th>
                <td>
                  {language == 'de'
                    ? moment(clickElement.data.datetime_local)
                        // .utc()
                        .format('HH:mm:ss,S')
                    : moment(clickElement.data.datetime_local)
                        // .utc()
                        .format('HH:mm:ss.S')}{' '}
                </td>
              </tr>
              <tr>
                <th>Magnitude:</th>
                <td>
                  {typeof clickElement.data.reference_magnitude[0] == 'number'
                    ? pointOrComma(
                        clickElement.data.reference_magnitude[0],
                        language,
                        true,
                      )
                    : 'test'}{' '}
                  (
                  {clickElement.data.reference_magnitude[1] ??
                    clickElement.data.reference_magnitude[1]}
                  )
                </td>
              </tr>
              <tr>
                <th>{language == 'de' ? 'Herdtiefe:' : 'Depth:'}</th>
                <td>{Math.round(clickElement.data.depth)} km</td>
              </tr>
              <tr>
                <th>{language == 'de' ? 'Epizentrum:' : 'Location'} </th>
                <td>
                  {language == 'de'
                    ? clickElement.data.lat_lon_text.replaceAll('.', ',')
                    : clickElement.data.lat_lon_text}
                  {/* {clickElement.data.lat}°N, {clickElement.data.lon}°O */}
                  {/* <a href="https://maps.google.com">GoogleMaps</a>) */}
                </td>
              </tr>
              <tr>
                <th style={{ verticalAlign: 'top' }}>
                  {language == 'de' ? 'Entfernungen:' : 'Distance'}
                </th>
                <td>
                  <ul style={{ margin: 0 }}>
                    {clickElement.data.places.map((el, index) => (
                      <li key={index}> {el}</li>
                    ))}
                  </ul>
                </td>
              </tr>
              {clickElement.data.press_text != '' ? (
                <tr>
                  <th style={{ verticalAlign: 'top' }}>
                    {language == 'de' ? 'Erdbebenmeldung' : 'Report'}
                  </th>
                  <td>{clickElement.data.press_text}</td>
                </tr>
              ) : null}
              <tr>
                <th>{language == 'de' ? 'Quelle' : 'Source'}:</th>
                <td>{clickElement.data.author}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.imageContainer}>
          <a
            href={`https://www.zamg.ac.at/zamgWeb/geophysik/vbebenkarte_xml/quakes/evid${clickElement.data.event_id}/wf_evid${clickElement.data.event_id}.png`}
            target="_blank"
          >
            <img
              src={`https://www.zamg.ac.at/zamgWeb/geophysik/vbebenkarte_xml/quakes/evid${clickElement.data.event_id}/wf_evid${clickElement.data.event_id}.png`}
              alt="Bild eines Seismogrammes"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default BebenDetailView;
