import React from 'react';
import WeatherTable from './WeatherforecastTable';
import styles from './WeatherforecastTable.module.css';

interface TableForecastProps {
  data: {
    variation: string;
  };
  combinedData: any; // Adjust the type according to your data structure
  markerdata?: {
    data: unknown;
  };
  FsetTableMode: (isActive: boolean, mode: string) => void;
  selectedDropdownElement: any; // Adjust type if known
  language: string;
}

const TableForecast: React.FC<TableForecastProps> = ({
  children,
  data,
  combinedData,
  markerdata,
  FsetTableMode,
  selectedDropdownElement,
  language,
}) => {
  return (
    <div className={`${styles.parentTableForecast} `}>
      {/* Conditionally render weatherforecast buttons */}
      <div className={styles.table_modeParent}>{children}</div>
      {/* WeatherTable component */}
      <WeatherTable
        markerdata={combinedData}
        isToday={combinedData.data.features[0].properties.forecast[0].time}
        data={data}
        selectedDropdownElement={selectedDropdownElement}
        language={language}
      />
    </div>
  );
};

export default TableForecast;
