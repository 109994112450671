import React, { useEffect } from 'react';
import { Icon } from '@plone/volto/components';
import Drop_Weather_Midnight_16px from '../../../icons/austria/Drop_Weather_Midnight_16px.svg';

import { useSelector } from 'react-redux';
import styles from './WeatherforecastChartWidget.module.css';

import {
  mappingSymbToText,
  mappingSymbToTextEnglisch,
} from '../../../constants/WeatherforecastHelper';
// import { mappingSymbToTextEnglisch } from '../../constants/WeatherforecastHelper';
import WeatherForecastHelperSvgDay from '../../../constants/WeatherForecastHelperSvgDay';

import moment from 'moment';
import WeatherForecastHelperSvgNight from '../../../constants/WeatherForecastHelperSvgNight';
import { isNighttime } from '../../utils/isNighttime';
moment.locale('de');

const WeatherforecastChartWidget = ({
  dailyMetainformation,
  forecastdata,
  language,
}) => {
  const clickXAxis = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata['clickXAxis']
      : {},
  );

  // const [dateTime, setDateTime] = useState(new Date());
  const nowTime = moment().utc().format('YYYY-MM-DDTHH:00:00[Z]');

  const getSvgImage = (symboleNumber, time, nxtTime) => {
    let yesOrno = isNighttime(time);
    function isBetween6And18(timestamp) {
      const hour = timestamp.hour(); // Get the hour component (0-23)
      return hour >= 6 && hour <= 18;
    }
    const timestamp1 = moment(time);
    const timestamp2 = moment(nxtTime);
    const differenceInHours = timestamp2.diff(timestamp1, 'hours');
    if (differenceInHours === 6) {
      if (isBetween6And18(timestamp1) && isBetween6And18(timestamp2)) {
        yesOrno = true;
      }
    }

    if (yesOrno) return WeatherForecastHelperSvgDay[symboleNumber];
    else return WeatherForecastHelperSvgNight[symboleNumber];
  };

  let findIndex = forecastdata?.data?.timestamps.findIndex((i) => i == nowTime);

  if (findIndex == null && forecastdata?.data?.reference_time) {
    findIndex = forecastdata.data.timestamps.findIndex(
      (i) => i == forecastdata.data.reference_time,
    );
    findIndex = findIndex + 1; // weil in der forecast chart letztes "null" relöscht wird und somit alles um eins verschoben wird
  } else if (findIndex == null && !forecastdata?.data?.reference_time) {
    findIndex = false;
  }

  const formatTimeRange = (index) => {
    let start = forecastdata.data.timestamps[index];
    start = moment(start).format('dddd, DD.MM.YYYY HH:mm');
    let end = forecastdata.data.timestamps[index + 1];
    end = end ? moment(end).format('HH:mm') : '';
    // const start = moment(time).format('dddd, DD.MM.YYYY HH:mm');
    // const end = moment(time).add(1, 'hour').format('HH:mm');
    return `${start} – ${end}`;
  };

  const params = forecastdata?.data.features[0].properties.parameters ?? null;
  let whichIndex = clickXAxis ? clickXAxis : findIndex;

  const newTemp = params?.t2m.data[whichIndex];
  const newRain = params?.rr.data[whichIndex] ?? null;
  const newSy = params?.sy.data[whichIndex] ?? null;

  return (
    <>
      <div
        className={styles.weatherWidgetMain}
        aria-label={`Detaildarstellung von ${formatTimeRange(whichIndex)} `}
      >
        {typeof clickXAxis == 'number' &&
          forecastdata?.data &&
          forecastdata.data?.features[0].properties?.parameters?.sy?.data[
            clickXAxis
          ] &&
          forecastdata.data.newtimestamps &&
          forecastdata.data.newtimestamps[clickXAxis] && (
            <Icon
              name={getSvgImage(
                forecastdata.data.features[0].properties.parameters.sy.data[
                  clickXAxis
                ],
                forecastdata.data.newtimestamps[clickXAxis],
                forecastdata.data.newtimestamps[clickXAxis + 1],
              )}
              size="80px"
              className="svg_icon"
              title={
                mappingSymbToText[
                  parseInt(
                    forecastdata.data.features[0].properties.parameters.sy.data[
                      clickXAxis
                    ],
                  )
                ]
              }
            />
          )}

        {clickXAxis == false &&
        typeof clickXAxis != 'number' &&
        forecastdata.data &&
        findIndex ? (
          <Icon
            name={getSvgImage(
              forecastdata.data.features[0].properties.parameters.sy.data[
                findIndex
              ],
              forecastdata.data.timestamps[findIndex],
              forecastdata.data.timestamps[findIndex + 1],
            )}
            size="80px"
            className="svg_icon"
            title={
              mappingSymbToText[
                parseInt(
                  forecastdata.data.features[0].properties.parameters.sy.data[
                    findIndex
                  ],
                )
              ]
            }
          />
        ) : null}
        {dailyMetainformation && (
          <div
            aria-label={`Ort: ${dailyMetainformation.name}. Seehöhe: ${dailyMetainformation.elevation}m. Akutelle modellbasierte Temeratur: ${newTemp}Grad, Niederschagsmenge:${newRain}mm, Wetter:${mappingSymbToText[newSy]}`}
          >
            <div className={styles.title}>
              <p>{dailyMetainformation.name}</p>
            </div>
            <div>
              <p style={{ margin: 0, textWrap: 'balance' }}>
                {/* {formattedDate} {prevHour.toString().padStart(2, '0')}:00 -{' '}
              {fullHour.toString().padStart(2, '0')}:00{' '} */}
                {formatTimeRange(whichIndex)}
              </p>

              <p style={{ margin: 0 }}>
                {dailyMetainformation.elevation}m{' '}
                {language === 'de' ? 'Seehöhe' : 'Altitude'}
              </p>
            </div>
            <div>
              <div className={styles.widgetTempSlahRain}>
                {language === 'de'
                  ? Number.isInteger(newTemp)
                    ? newTemp
                    : newTemp.toFixed(1).replace('.', ',')
                  : newTemp.toFixed(1)}{' '}
                °C <div className={styles.slash}> | </div>
                <Icon
                  name={Drop_Weather_Midnight_16px}
                  size="20px"
                  className={styles.iconMargin}
                />
                {newRain != null && newRain >= 0
                  ? language === 'de'
                    ? Number.isInteger(newRain)
                      ? newRain
                      : newRain.toFixed(1).replace('.', ',')
                    : newRain.toFixed(1)
                  : 'regen'}{' '}
                mm <div className={styles.slash}> | </div>
                <p className={styles.weatherText}>
                  {language == 'de'
                    ? mappingSymbToText[newSy]
                    : mappingSymbToTextEnglisch[newSy]}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default WeatherforecastChartWidget;
