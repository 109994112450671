import React, { useEffect, useState } from 'react';
import styles from './TawesLegend.module.css'; // Ensure to create this file for styling
import { Icon } from '@plone/volto/components';
import NODATA_SVG from '../../../icons/austria/Weather_NoData_Midnight_16px.svg';
import cx from 'classnames';
import moment from 'moment';

const temepraturRanges = [
  // { color: 'darkblue', description: '< -35 °C' },
  // { color: '#003366', description: '-35 bis -30 °C' },
  // { color: '#004080', description: '-30 bis -25 °C' },
  { color: '#2E009F', description: '<-25' },
  { color: '#5833FE', description: '-20' },
  { color: '#7366FE', description: '-15' },
  { color: '#8C99FD', description: '-10' },
  { color: '#A6CCFD', description: '-5' },
  { color: '#C0FFFC', description: '0' },
  { color: '#8DF3D8', description: '5' },
  { color: '#5AE291', description: '10' },
  { color: '#75CC41', description: '15' },
  { color: '#BFCE40', description: '20' },
  { color: '#F9F200', description: '25' },
  { color: '#F29707', description: '30' },
  { color: '#E75C13', description: '35' },
  { color: '#CC2C01', description: '40' },
  { color: '', description: '>40' },
  // { color: '#000000', description: 'keine Daten' },
];
const niederschlagRanges = [
  { color: '#F3F9FF', description: '0' },
  { color: '#D2E9FF', description: '0.1' },
  { color: '#A6CCFD', description: '1' },
  { color: '#8C99FD', description: '3' },
  { color: '#7366FE', description: '5' },
  { color: '#5833FE', description: '10' },
  { color: '#3500B7', description: '15' },
  { color: '#701F80', description: '30' },
  { color: '#8C11AA', description: '60' },
  { color: '', description: '>60' },
  // { color: '#000000', description: 'keine Daten' },
];
const windSpeedRanges = [
  { color: '#F3F9FF', description: '0' },
  { color: '#C0FFFC', description: '10' },
  { color: '#8DF3D8', description: '20' },
  { color: '#75CC41', description: '30' },
  { color: '#BFCE40', description: '40' },
  { color: '#F9F200', description: '50' },
  { color: '#F29707', description: '60' },
  { color: '#E75C13', description: '70' },
  { color: '#CC2C01', description: '80' },
  { color: '#B23C13', description: '90' },
  { color: '#912E48', description: '100' },
  { color: '#801F66', description: '110' },
  { color: '#8C11AA', description: '120' },
  { color: '', description: '>120' },
];
const sunRanges = [
  { color: '#5833FE', description: '0' },
  { color: '#7366FE', description: '1' },
  { color: '#A6CCFD', description: '2' },
  { color: '#D2E9FF', description: '3' },
  { color: '#C0FFFC', description: '4' },
  { color: '#8CF4D8', description: '5' },
  { color: '#5AE291', description: '6' },
  { color: '#75CC41', description: '7' },
  { color: '#F9F200', description: '8' },
  { color: '#F29707', description: '9' },
  { color: '', description: '10' },
];

const feuchteRanges = [
  // { color: 'FFFFFF', description: 'keine Daten' },
  { color: '#F29707', description: '0' },
  { color: '#F9F200', description: '10' },
  { color: '#75CC41', description: '20' },
  { color: '#5AE291', description: '30' },
  { color: '#8CF4D8', description: '40' },
  { color: '#C0FFFC', description: '50' },
  { color: '#D2E9FF', description: '60' },
  { color: '#A6CCFD', description: '70' },
  { color: '#7366FE', description: '80' },
  { color: '#5833FE', description: '90' },
  { color: '', description: '100' },
  // { color: '#000000', description: 'keine Daten' },
];

const druckRanges = [
  // { color: 'FFFFFF', description: 'keine Daten' },
  // { color: '#2E4038', description: ' 1 bis  5 %' },
  // { color: '#779382', description: ' 5 bis 10 %' },
  // { color: '#A9B497', description: '10 bis 20 %' },
  // { color: '#C3A06C', description: '20 bis 30 %' },
  // { color: '#D3C9A2', description: '30 bis 40 %' },
  // { color: '#C9B47E', description: '40 bis 50 %' },
  // { color: '#9D8161', description: '50 bis 60 %' },
  // { color: '#795548', description: '60 bis 70 %' },
  { color: '#5833FE', description: '<995' },
  { color: '#7366FE', description: '995' },
  { color: '#A6CCFD', description: '1000' },
  { color: '#C0FFFC', description: '1005' },
  { color: '#8CF4D8', description: '1010' },
  { color: '#5AE291', description: '1015' },
  { color: '#75CC41', description: '1020' },
  { color: '#F9F200', description: '1025' },
  { color: '#F29707', description: '1030' },
  { color: '', description: '>1030' },

  // { color: '#000000', description: 'keine Daten' },
];

const earthquake = [
  // { color: 'FFFFFF', description: 'keine Daten' },
  { color: '#FFFFFF', description: 'I' },
  { color: '#A6CCFD', description: 'II' },
  { color: '#C0FFFC', description: 'III' },
  { color: '#8CF4D8', description: 'IV' },
  { color: '#75CC41', description: 'V' },
  { color: '#F9F200', description: 'VI' },
  { color: '#F29707', description: 'VII' },
  { color: '#E75C13', description: 'VIII' },
  { color: '#CC2C01', description: 'IX' },
  { color: '', description: '' },
  // { color: '#000000', description: 'keine Daten' },
];

// laut bild haben die ein Border?
const earthquakeAge = [
  // { color: 'FFFFFF', description: 'keine Daten' },
  { color: '#3300b9', description: '<1 Tag', descriptionEN: '<1 day' },
  { color: '#8b9af3', description: '1-3 Tage', descriptionEN: '1-3 day' },
  {
    color: '#d1e7fc',
    description: '4 – 14 Tage',
    descriptionEN: '4 – 14 day',
  },

  // { color: '#000000', description: 'keine Daten' },
];
const TawesLegend = React.memo(
  ({
    parameterValue,
    types = 'tawes',
    language,
    markerdata,
    legendGridRow,
  }) => {
    const getTime = markerdata?.features?.find(
      (i) => i.properties?.timestep != null,
    )?.properties.timestep;
    // console.log(getTime);

    const selParamter = [
      temepraturRanges,
      niederschlagRanges,
      windSpeedRanges,
      sunRanges,
      feuchteRanges,
      druckRanges,
      earthquake,
      earthquakeAge,
    ];
    const selParamterName = [
      { name: 'Temperatur', unit: '°C', nameEn: 'Termperatur' },
      { name: 'Niederschlag', unit: 'mm/h', nameEn: 'Precipitation' },
      { name: 'Wind', unit: 'km/h', nameEn: 'Wind' },
      { name: 'Sonne', unit: 'min', nameEn: 'Sun' },
      { name: 'Feuchte', unit: '%', nameEn: 'Humidity' },
      { name: 'Druck', unit: 'hPa', nameEn: 'Pressure' },
      // beben:
      { name: 'Intensität', unit: '' },
      { name: 'Vergangene Zeit', unit: '', nameEn: 'Past time' },
    ];

    // const [par, setPar] = useState(false);
    // useEffect(() => {
    //   setPar(parameterValue);
    // }, [parameterValue]);
    const par = parameterValue;
    return (
      <div
        className={cx(styles.legend, {
          [styles.legendCenter]: par === 7,
          [styles.legendGridRow]: legendGridRow,
        })}
      >
        {/* <h1>Legende</h1> */}
        <p className={styles.pClass}>
          {typeof par == 'number' &&
            (language == 'de'
              ? selParamterName[par].name
              : selParamterName[par].nameEn)}{' '}
          {typeof par == 'number' && par < 6 ? (
            <span>
              {typeof par == 'number' && par < 6 && language == 'de'
                ? 'gemessen in '
                : 'measured in '}
            </span>
          ) : null}
          {typeof par == 'number' && selParamterName[par].unit}
        </p>
        <div
          className={cx(styles.legendFlexpart, {
            [styles.legendFlexpartGap]: par === 7,
          })}
        >
          {par > 0 || par === 0
            ? selParamter[par].map((range, index) => (
                <div
                  key={index}
                  className={cx(styles.segmentWrapper, {
                    [styles.flexAlignCenter]: par === 6,
                    [styles.flexCol]: par === 7,
                  })}
                >
                  <div
                    className={cx(styles.segment, {
                      [styles.withBorder]: index < selParamter[par].length - 1,
                      [styles.segmentNoMargin]: par === 7,
                    })}
                    style={{ backgroundColor: range.color }}
                  ></div>
                  {par === 7 ? (
                    <span>
                      {language == 'de'
                        ? range.description
                        : range.descriptionEN}
                    </span>
                  ) : (
                    range.description
                  )}
                </div>
              ))
            : ''}
        </div>
        <div className={styles.iconPclass}>
          {types === 'tawes' ? (
            <div className={styles.iconPclassChild}>
              <Icon
                name={NODATA_SVG}
                size="16px"
                className="svg_icon"
                title="Keine Daten vorhanden"
              />
              <p className={styles.pClassSmall}>
                {language == 'de' ? 'Keine Daten' : 'No data'}
              </p>
            </div>
          ) : null}

          {/* {types === 'beben' ? (
            <div>
              <Icon
                name={NODATA_SVG}
                size="16px"
                className="svg_icon"
                title="Keine Daten vorhanden"
              />
              <p className={styles.pClassSmall}>Keine Daten</p>
            </div>
          ) : (
            ''
          )} */}
          {/* <p className={styles.pClassSmall}> </p> */}
        </div>
        {markerdata && (
          <div className={styles.timestamp}>
            {moment(getTime).format('DD.MM.YYYY')}
            <div className={styles.slash}>|</div>
            {moment(getTime).format('HH:mm')}
          </div>
        )}
      </div>
    );
  },
);

export default TawesLegend;
