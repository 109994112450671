// BebenTabelle.js
// zwei arte, einmal meldungen, einmal beben abhängig von dem gewählten button above? oder immer nur aktuelle?

import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
// import './WeatherforecastTable.css';
// import weatherforecast11SVG from '../../icons/weatherforecast/11.svg';
import styles from '../Forecast/WeatherforecastTable.module.css';
import searchIcon from '../../../icons/austria/Lupe_Midnight_32px.svg';
import searchUpDown from '../../../icons/austria/ListChange_Midnight_12px.svg';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// import cx from 'classnames';
import { Icon } from '@plone/volto/components';
// import Icon_Windrichtung from '../../icons/austria/Arrow_Weather_Midnight_16px_counterClockwise.svg';

import Icon_Windrichtung from '../../../icons/austria/Arrow_Weather_Midnight_16px_Clockwise.svg';

import classnames from 'classnames';
import { navItemTawes, mapping } from '../../../constants/TawesHelper';
import InformationStatus from '../ui/InformationStatus';
moment.locale('de');

// import TawesTabelleNavbar from './TawesTabelleNavbar';

const tableHeaderTawes = [
  { nameDe: 'Station', nameEn: 'Station', nameSort: 'name' },

  { nameDe: 'Messdatum', nameEn: 'Measurement date', nameSort: '' },
  { nameDe: 'Messhöhe', nameEn: 'Altitude', nameSort: 'elevation' },
  { nameDe: 'Breite, Länge', nameEn: 'Lat/Long', nameSort: '' },
  // { nameDe: 'Lon', nameEn: 'Lon', nameSort: 'Lon' },
  { nameDe: 'Parameter', nameEn: 'Parameter', nameSort: 'Parameter' },
  // { nameDe: 'Region', nameEn: 'Region', nameSort: 'Region' },
];

const tableHeaderTawesWind = [
  { nameDe: 'Station', nameEn: 'Station', nameSort: 'name' },

  { nameDe: 'Messdatum', nameEn: 'Measurement date', nameSort: '' },
  { nameDe: 'Messhöhe', nameEn: 'Altitude', nameSort: 'elevation' },
  { nameDe: 'Breite, Länge', nameEn: 'Lat/Long', nameSort: '' },
  {
    par: 'FFAM',
    nameDe: 'Windgeschwindigkeit',
    nameEn: 'Wind',
    nameSort: 'FFAM',
  },
  {
    par: 'DD',
    nameDe: 'Windrichtung',
    nameEn: 'Winddirection',
    nameSort: 'DD',
  },
  {
    par: 'FFX',
    nameDe: 'Windspitzen',
    nameEn: 'Gust',
    nameSort: 'FFX',
  },
  {
    par: 'DDX',
    nameDe: 'Windspitzenrichtung',
    nameEn: 'Gustsdirection',
    nameSort: 'DDX',
  },
];

const windArray = ['FFAM', 'DD', 'FFX', 'DDX'];

const getRotationClass = (rotation) => {
  const rot = rotation;
  if (!rot) return styles[`rotate-NOT-available`];
  const numClasses = 16;
  const segmentSize = 360 / numClasses;
  const classIndex = Math.floor((rot % 360) / segmentSize);
  return styles[`rotate-${classIndex}`];
};
function winddirection(windrichtung) {
  let windr = Math.round((windrichtung % 360) / 45) % 8;
  const directions = {
    arrowN: { nicename: 'Nord', svgname: 'arrowN' }, // Swap North with South
    arrowNE: { nicename: 'Nordost', svgname: 'arrowNE' }, // Swap Northeast with Southwest
    arrowE: { nicename: 'Ost', svgname: 'arrowE' }, // Swap East with West
    arrowSE: { nicename: 'Südost', svgname: 'arrowSE' }, // Swap Southeast with Northwest
    arrowS: { nicename: 'Süd', svgname: 'arrowS' }, // Swap South with North
    arrowSW: { nicename: 'Südwest', svgname: 'arrowSW' }, // Swap Southwest with Northeast
    arrowW: { nicename: 'West', svgname: 'arrowW' }, // Swap West with East
    arrowNW: { nicename: 'Nordwest', svgname: 'arrowNW' }, // Swap Northwest with Southeast
  };
  // const directions = ['North', 'Northeast', 'East', 'Southeast', 'South', 'Southwest', 'West', 'Northwest'];

  // Determine the wind direction category based on the angle
  return directions[Object.keys(directions)[windr]].nicename;
}

const TawesTabelle = ({
  markerdata,
  // data,
  filteredElement,
  pointsState,
  // handleStationclick,
}) => {
  const dispatch = useDispatch();
  const [stationAustria, setStationAustria] = useState();
  const [stationFilteredAustria, setStationFilteredAustria] = useState();

  // console.log(tawesSubmenu);
  // console.log(navItemTawes[filteredElement].par[0].par);
  const tawesSubmenu = navItemTawes[filteredElement].par[0].par;

  const clickElement = useSelector(
    // (state) => state.geospheredataplatformdata?[data.servicename] ? state.geospheredataplatformdata?[data.servicename].data : {}
    (state) =>
      state.geospheredataplatformdata
        ? state.geospheredataplatformdata['SET_CLICK_MARKER_TABLE_INTERACTION']
        : {},
  );
  const pointInformation = useSelector(
    // (state) => state.geospheredataplatformdata?[data.servicename] ? state.geospheredataplatformdata?[data.servicename].data : {}
    (state) =>
      state.geospheredataplatformdata
        ? state.geospheredataplatformdata[pointsState]
        : {},
  );
  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );
  const stationAustriaHeader =
    filteredElement === 2 ? tableHeaderTawesWind : tableHeaderTawes;

  useEffect(() => {
    if (!markerdata || !pointInformation?.data) return;

    // const merge = markerdata + MediaMeta
    // Adding the 'name' property based on the 'station'
    const updatedObjectArray = markerdata.features.map((obj) => {
      const id = obj.properties.station;
      const name = pointInformation.data.mapping[id]?.name || null;
      const klima_id = pointInformation.data.mapping[id]?.klima_id || null;
      const elevation = pointInformation.data.mapping[id]?.elevation || null;
      return {
        ...obj,
        properties: {
          ...obj.properties,
          name,
          klima_id,
          elevation,
        },
      };
    });
    const sortedData = sortConfig.key
      ? sortFeatures(sortConfig.key, updatedObjectArray)
      : updatedObjectArray.sort((a, b) =>
          a.properties.name.localeCompare(b.properties.name),
        );

    setStationAustria(sortedData);
    setStationFilteredAustria(sortedData);
  }, [markerdata, pointInformation]);

  // const [stationAustriaHeader, setStationAustriaHeader] =
  //   useState(tableHeaderTawes);

  const handleRowClick = useCallback(
    (station) => {
      station.owner = 'tawes';
      dispatch({
        type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
        payload: station,
      });
    },
    [dispatch],
  );

  const inputRef = useRef();

  // Function to handle input change
  const handleChange = useCallback(
    (event) => {
      const filteredStations = stationAustria.filter((station) =>
        station.properties.name
          .toLowerCase()
          .includes(inputRef.current.value.toLowerCase()),
      );
      // sortFeatures(sortConfig.key, filteredStations);
      setStationFilteredAustria(filteredStations);
    },
    [stationAustria, inputRef],
  );

  // Function to handle click on the div
  const handleDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field when the div is clicked
    }
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const sortFeatures = useMemo(() => {
    const getSortValue = (feature, sortBy) => {
      if (sortBy === 'Parameter') sortBy = tawesSubmenu;
      return (
        feature.properties[sortBy] ||
        feature.properties.parameters[sortBy]?.data ||
        null
      );
    };

    return (sortBy, data) => {
      if (!sortBy) return data;

      const direction =
        sortConfig.key === sortBy && sortConfig.direction === 'asc'
          ? 'desc'
          : 'asc';
      const sortedData = [...data].sort((a, b) => {
        const a_ = getSortValue(a, sortBy);
        const b_ = getSortValue(b, sortBy);
        if (a_ < b_) return direction === 'asc' ? -1 : 1;
        if (a_ > b_) return direction === 'asc' ? 1 : -1;
        return 0;
      });

      setSortConfig({ key: sortBy, direction });
      return sortedData;
    };
  }, [sortConfig, tawesSubmenu]);

  // const getRightDate = (data, timestamps) => {
  //   // Find the index of the value 24 in valueArray
  //   const index = data.findIndex((value) => value != null);

  //   // Get the corresponding timestamp if index is found
  //   const correspondingTimestamp =
  //     index !== -1 ? timestamps[index] : 'Not found';

  //   if (correspondingTimestamp == 'Not found') return 'Keine Daten';
  //   return moment(correspondingTimestamp).format('YYYY-MM-DD HH:mm');
  // };
  // const rotationClass = getRotationClass(rotation);
  return (
    <div className={styles.gridRow3}>
      {!(!markerdata || pointInformation?.data == false) ? (
        <div className={styles.weatherprognoseTable1}>
          <div className={styles.divInputSearch} onClick={handleDivClick}>
            <Icon name={searchIcon} size="20px" title="Lupe" />
            <input
              type="text"
              placeholder={
                language === 'de' ? 'Ortssuche...' : 'Location search...'
              }
              onChange={(e) => handleChange(e)}
              className={styles.inputSearch}
              ref={inputRef}
              aria-label="Ortssuche"
            />
          </div>

          <div className={styles.weatherprognoseTable_div}>
            <table
              className={classnames(styles.weatherprognoseTable_table, {
                [styles.weatherprognoseTable_table_wind]: filteredElement === 2,
              })}
            >
              <thead>
                <tr>
                  {/* Header row with days */}
                  {stationFilteredAustria &&
                    stationAustriaHeader.map((item, index) => (
                      <th
                        style={{ position: 'sticky', zIndex: 1, top: 0 }}
                        key={item.nameDe}
                        aria-label={`${language === 'de' ? item.nameDe : item.nameEn}`}
                        scope="col"
                        onClick={() =>
                          setStationFilteredAustria(
                            sortFeatures(item.nameSort, stationFilteredAustria),
                          )
                        }
                      >
                        <div className={styles.thFlex}>
                          <div className={styles.flexCol}>
                            <span>
                              {language == 'de'
                                ? item.nameDe === 'Parameter'
                                  ? mapping.find((i) => i.par === tawesSubmenu)
                                      .nameDe
                                  : item.nameDe
                                : null}
                              {language == 'en'
                                ? item.nameDe === 'Parameter'
                                  ? mapping.find((i) => i.par === tawesSubmenu)
                                      .nameEn
                                  : item.nameEn
                                : null}
                            </span>
                            {/* meter einheit */}
                            {index === 2 ? <span>(m)</span> : ''}
                            {/* meter einheit */}
                            {index === 4 ? (
                              <span>
                                {
                                  mapping.find((i) => i.par === tawesSubmenu)
                                    .unit
                                }
                              </span>
                            ) : (
                              ''
                            )}
                            {index === 6 ? (
                              <span>
                                {
                                  mapping.find((i) => i.par === tawesSubmenu)
                                    .unit
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          {index != 1 && index != 3 ? (
                            <Icon
                              name={searchUpDown}
                              size="12px"
                              title="Sortieren nach Parameter"
                            />
                          ) : null}
                        </div>
                      </th>
                    ))}
                  {/* {stationAustriaHeader.map((item, index) => item.nameDe)} */}
                </tr>
              </thead>
              <tbody>
                {/* Rows for each station */}

                {stationFilteredAustria &&
                  stationFilteredAustria.map((station, stationIndex) => (
                    <tr
                      role="button"
                      key={stationIndex}
                      onClick={() => handleRowClick(station)}
                      className={classnames({
                        [styles.active]:
                          station.properties.station ===
                          clickElement?.data?.properties.station,
                      })}
                      aria-label={`Link zur Detailanalyse für ${station.properties.name} `}
                      // style={{ wordBreak: 'break-all' }}
                    >
                      <td
                        aria-label={`Vorhersageort:${station.properties.name}`}
                      >
                        {station.properties.name.split('/').join('/ ')}
                      </td>

                      <td
                        aria-label={'Zeitpunkt:' + station.properties.timestep}
                      >
                        <span style={{ textWrap: 'nowrap', display: 'flex' }}>
                          {moment(station.properties.timestep).format(
                            'DD.MM.YYYY ',
                          )}
                          <div
                            style={{
                              color: '#bfce40',
                              marginLeft: '3px',
                              marginRight: '3px',
                            }}
                          >
                            |
                          </div>
                          {moment(station.properties.timestep).format(' HH:mm')}
                        </span>
                      </td>
                      <td
                        aria-label={`Stationshöhe: ${station.properties.elevation}m`}
                      >
                        {station.properties.elevation}
                      </td>
                      <td
                        aria-label="Geolokalisierung der Station"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {station.geometry.coordinates[1].toFixed(4)},{' '}
                        {station.geometry.coordinates[0].toFixed(4)}
                      </td>
                      {filteredElement != 2 ? (
                        <td
                          aria-label={
                            'Messwert: ' +
                              station.properties.parameters[tawesSubmenu]
                                .data ===
                            null
                              ? 'Keine Daten'
                              : station.properties.parameters[tawesSubmenu].data
                          }
                        >
                          {station.properties.parameters[tawesSubmenu].data ===
                          null
                            ? 'Keine Daten'
                            : station.properties.parameters[tawesSubmenu].data}
                        </td>
                      ) : (
                        <td
                          aria-label={
                            'Messwert: ' +
                              station.properties.parameters[tawesSubmenu]
                                .data ===
                            null
                              ? 'Keine Daten'
                              : Math.round(
                                  station.properties.parameters[tawesSubmenu]
                                    .data *
                                    3.6 *
                                    100,
                                ) / 100
                          }
                        >
                          {station.properties.parameters[tawesSubmenu].data ===
                          null
                            ? 'Keine Daten'
                            : Math.round(
                                station.properties.parameters[tawesSubmenu]
                                  .data *
                                  3.6 *
                                  100,
                              ) / 100}
                        </td>
                      )}
                      {filteredElement == 2 ? (
                        <td
                          aria-label={
                            'Windrichtung in  Grad:' +
                            station.properties.parameters[windArray[1]].data
                          }
                        >
                          {station.properties.parameters[windArray[1]].data}
                          {'° '}
                          <Icon
                            name={Icon_Windrichtung}
                            size="13px"
                            className={getRotationClass(
                              station.properties.parameters[windArray[1]].data,
                            )}
                            title={
                              winddirection(
                                station.properties.parameters[windArray[1]]
                                  .data,
                              ) +
                              ' ' +
                              'Wind'
                            }
                          />
                        </td>
                      ) : (
                        ''
                      )}
                      {filteredElement == 2 ? (
                        <td
                          aria-label={
                            'Windspitzen in  km/h:' +
                              station.properties.parameters[windArray[2]]
                                .data ===
                            null
                              ? 'Keine Daten'
                              : Math.round(
                                  station.properties.parameters[windArray[2]]
                                    .data *
                                    3.6 *
                                    100,
                                ) / 100
                          }
                        >
                          {station.properties.parameters[windArray[2]].data ===
                          null
                            ? 'Keine Daten'
                            : Math.round(
                                station.properties.parameters[windArray[2]]
                                  .data *
                                  3.6 *
                                  100,
                              ) / 100}
                        </td>
                      ) : (
                        ''
                      )}
                      {filteredElement == 2 ? (
                        <td
                          aria-label={
                            'Windrichtung in Grad:' +
                            station.properties.parameters[windArray[3]].data
                          }
                        >
                          {station.properties.parameters[windArray[3]].data}
                          {'° '}
                          <Icon
                            name={Icon_Windrichtung}
                            size="13px"
                            className={getRotationClass(
                              station.properties.parameters[windArray[3]].data,
                            )}
                            title={winddirection(
                              station.properties.parameters[windArray[3]].data,
                            )}
                          />
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <InformationStatus language={language} outputtext="" />
      )}
    </div>
  );
};

export default TawesTabelle;
