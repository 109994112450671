import { useState, useEffect, useCallback, useMemo } from 'react';
import { SimpleMap } from '../..';
import { useDispatch, useSelector } from 'react-redux';

import {
  getGeospheredataplatformdata,
  resetObject,
  setComponent,
  setLanguage,
} from '../../../actions';

import BebenTabelle from '../../Components/Earthquake/BebenTabelle';
import TawesTabelle from '../../Components/Messstation/TawesTabelle';
import TawesHighcharts from '../../Components/Messstation/TawesHighcharts';
import TawesLegend from '../../Components/Messstation/TawesLegend';
import cx from 'classnames';
import autIcon from '../../../icons/austria/aut.svg';
import BebenDetailView from '../../Components/Earthquake/BebenDetailView';
// import startbild from '../../../icons/austria/startbild.png';
import moment from 'moment';
import 'moment/locale/de'; // Import the German locale
import BebenMarkers from '../../Components/Earthquake/BebenMarker';
import TawesMarker from '../../Components/Messstation/TawesMarker';
import VariationNavButtons from '../../Components/Shared/VariationNavButtons';
import ExternalLinkButtons from '../../Components/Shared/ExternalLinkButtons';
import WarningBlock from '../../Components/WarningBlock';
import ControlPanels from '../../Components/ControlPanels';
import TableForecast from '../../Components/Forecast/TableForecast';
import WeatherForecastTextBlock from '../../Components/WeatherForecastTextBlock';
import WeatherForecastChartBlock from '../../Components/WeatherForecastChartBlock';
import WeatherForecastDropdown from '../../Components/Forecast/WeatherForecastDropdown';
import BebenLegenzSize from '../../Components/Earthquake/BebenLegenzSize';
import WeatherForecastInfoBlock from '../../Components/WeatherForecastInfoBlock';
import Skeleton from '../../Components/Shared/Skeleton';
import useHashChange from '../../../hooks/useHashChange';
import { setHash } from '../../utils/hashingHelpers';
import useGeoSphereMapViewData from './useGeoSphereMapViewData.hook';

moment.locale('de');

const View = ({ data, location = false }) => {
  const memoizedData = React.useMemo(() => data, [data]);
  const [mapClick, setmapClick] = useHashChange(memoizedData, location);

  const dispatch = useDispatch();

  const markerdataRaw = useSelector(
    (state) =>
      state.geospheredataplatformdata[memoizedData.servicename] ?? null,
    (prev, next) => prev === next,
  );

  const chartmodeRaw = useSelector(
    (state) =>
      state.geospheredataplatformdata[memoizedData.variation + 'CHART'],
  );
  const clickElementRaw = useSelector(
    (state) =>
      state.geospheredataplatformdata['SET_CLICK_MARKER_TABLE_INTERACTION'],
  );
  //works for weatherstations
  const combinedData = useSelector(
    (state) => state.geospheredataplatformdata['combinedData'] ?? null,
    (prev, next) => prev === next,
  );
  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );
  const warnstatus = useSelector(
    // (state) => state.geospheredataplatformdata?[data.servicename] ? state.geospheredataplatformdata?[data.servicename].data : {}
    (state) =>
      state.geospheredataplatformdata
        ? state.geospheredataplatformdata[memoizedData.warnstatus]
        : {},
  );

  useEffect(() => {
    // Set the moment locale based on the detected language
    if (language === 'de') {
      moment.locale('de');
    } else {
      moment.locale('en');
    }
  }, [language]);

  let markerdata = useMemo(() => markerdataRaw, [markerdataRaw]);

  const chartmode = useMemo(() => chartmodeRaw, [chartmodeRaw]);
  const clickElement = useMemo(() => clickElementRaw, [clickElementRaw]);
  // const fetchData = (serviceaddress, servicename) => {
  //   dispatch(getGeospheredataplatformdata(serviceaddress, servicename));
  // };
  const [dayClick, setdayClick] = useState(0);
  const handleClick = useCallback(
    (e) => {
      setdayClick(e);
    },
    [setdayClick],
  );
  const filterdDataF = (location, inputData) => {
    if (!Array.isArray(inputData)) return;

    let locationid = location === 0 ? 1 : location === 1 ? 2 : 3;
    const tempData = inputData.filter((i) => i.location_id <= locationid);

    return tempData;
  };
  const filteredMarkers = useMemo(() => {
    if (memoizedData.variation === 'beben' && markerdata?.data) {
      // Filter logic based on zoom level (value)

      return filterdDataF(dayClick, markerdata.data); // Custom visibility logic
    }
  }, [dayClick, markerdata, memoizedData.variation]);

  const handleResetFromChart = (tab) => {
    dispatch({ type: 'SET_CLICK_X_AXIS', payload: false });
    window.location.hash = `tab=${tab}`;
    dispatch(resetObject(data.highcharts_grid));
    if (markerdata?.data?.features?.length == 1) {
      dispatch(resetObject(data.servicename));
      dispatch(
        getGeospheredataplatformdata(data.serviceaddress, data.servicename),
      );
    }
  };

  const FsetTableMode = useCallback((tab) => {
    setHash('tab', tab);
  }, []);

  useGeoSphereMapViewData(memoizedData, warnstatus, markerdata);

  const [selectedDropdownElement, setselectedDropdownElement] = useState(0);
  const handleDropdown = useCallback((option) => {
    setselectedDropdownElement(option);
  }, []);
  const forecastTime =
    markerdata?.data?.features?.[0]?.properties?.forecast?.[0]?.time ?? null;
  const chartModeNot =
    chartmode?.active !== memoizedData.variation + 'CHART' ? true : false;
  return (
    <div className="block weatherforecastMain g">
      <div
        className="block geosphere_interactive_map"
        style={{ display: 'block' }}
      >
        {memoizedData.variation != 'warning' &&
          markerdata &&
          // markerdata.data &&
          language && (
            <WeatherForecastInfoBlock
              data={memoizedData}
              datavariation={data.variation}
              datainformationText={data.informationText}
              datainformationLink={data.informationLink}
              language={language}
              isToday={forecastTime}
              markerdataExists={markerdata.data == false}
              markerdataError={markerdata?.error}
              // figering out how display if a point doesn't exist
            />
          )}
        {markerdata && markerdata.data && (
          <WeatherForecastDropdown
            data={memoizedData}
            chartmode={chartModeNot}
            selectedDropdownElement={selectedDropdownElement}
            handleDropdown={handleDropdown}
            language={language}
          />
        )}
      </div>
      {/* Karte */}

      {memoizedData.variation != 'warning' &&
        !(chartmode && chartmode.active) &&
        markerdata &&
        markerdata.data && (
          <div className={cx('block', 'geosphere_interactive_map mt-bottom')}>
            {/* vorhersageButton karte / tabelle */}
            {/* Navbuttons */}
            <VariationNavButtons
              data={memoizedData}
              language={language}
              markerdata={markerdata}
              mapClick={mapClick}
              FsetTableMode={FsetTableMode}
            />
            {/* navbar vorhersage */}

            <ControlPanels
              markerdata={markerdata}
              data={memoizedData}
              onButtonClick={handleClick}
              isToday={forecastTime}
              numberOfDays={memoizedData.forcastdays}
              parameter={dayClick}
              tableActive={mapClick}
              language={language}
            />

            {/* tawesLegend */}
            {memoizedData.variation === 'tawes' && mapClick === 'map' && (
              <TawesLegend
                parameterValue={dayClick}
                type="tawes"
                language={language}
                markerdata={markerdata.data}
              />
            )}

            {memoizedData.variation === 'beben' && mapClick === 'map' ? (
              <div className="flexBebenLegend">
                {/* BEBEN LEGENDE!! */}
                {/* 6 : eathquake intensity */}

                {/* <TawesLegend
                  parameterValue={6}
                  types="beben"
                  language={language}
                /> */}

                {/* 7: earthquakeAge */}

                <TawesLegend
                  legendGridRow={4}
                  parameterValue={7}
                  types="beben"
                  language={language}
                />
                <BebenLegenzSize language={language} />
              </div>
            ) : null}
            {/* {mapClick === 'map' && markerdata == null ? <Skeleton /> : null} */}
            {mapClick === 'map' &&
              memoizedData &&
              ((memoizedData.variation != 'weatherforecast' &&
                markerdata?.data) ||
                (memoizedData.variation == 'weatherforecast' &&
                  combinedData?.data)) && (
                <SimpleMap
                  data={memoizedData}
                  day={dayClick}
                  stateAut={selectedDropdownElement}
                  markerdata={combinedData} //added combinedData here
                  // tawesSubmenu={tawesSubmenuClick}
                >
                  {memoizedData.variation === 'beben' &&
                    memoizedData &&
                    markerdata?.data &&
                    filteredMarkers && (
                      <BebenMarkers
                        filteredMarkers={filteredMarkers}
                        locationid={dayClick}
                        parentData={memoizedData}
                        language={language}
                      />
                    )}
                  {memoizedData.variation === 'tawes' &&
                    memoizedData &&
                    markerdata?.data?.features && (
                      <TawesMarker
                        language={language}
                        data={markerdata.data.features}
                        parameterNumber={dayClick}
                        pointsState={memoizedData.forecastPoints}
                        // tawesSubmenu={tawesSubmenu}
                      />
                    )}
                </SimpleMap>
              )}

            {/* Tawes tabelle */}
            {memoizedData.variation === 'tawes' &&
              markerdata?.data &&
              mapClick === 'table' && (
                <TawesTabelle
                  markerdata={markerdata.data}
                  // onChildClick={() => FsetTableMode(false, 'map')}
                  data={memoizedData}
                  pointsState={memoizedData.forecastPoints}
                  filteredElement={dayClick} // dayclick == 0:Tempteratur, 1:Niederschlag
                  // tawesSubmenu={tawesSubmenuClick}
                  // handleStationclick={handleStationclick}
                />
              )}
            {/* erdbeben tabelle */}
            {memoizedData.variation === 'beben' &&
              markerdata?.data &&
              mapClick === 'table' && (
                <BebenTabelle
                  markerdata={markerdata.data}
                  // onChildClick={() => FsetTableMode(false, 'map')}
                  // data={memoizedData}
                  filteredElement={dayClick}
                />
              )}
            {memoizedData.variation === 'beben' &&
              clickElement?.owner == 'beben' && (
                <BebenDetailView
                  clickElement={clickElement}
                  language={language}
                />
              )}
          </div>
        )}
      {/* Vorhersage + (punktabfrage) HIGHCHARTSDIAGRAMM DARSTELLEN */}
      {chartmode?.active === memoizedData.variation + 'CHART' &&
        markerdata &&
        markerdata?.data && (
          <WeatherForecastChartBlock data={data} chartmode={chartmode} />
        )}
      {markerdata == null && memoizedData.variation !== 'warning' ? (
        <Skeleton />
      ) : null}
      {/* Tabelle Vorhersage DARSTELLEN */}
      {/* {{ mapClick }} */}
      {chartmode?.active === memoizedData.variation + 'TABLE' &&
        combinedData?.data?.features && (
          <TableForecast
            chartmode={chartmode}
            data={memoizedData}
            combinedData={combinedData}
            markerdata={markerdata}
            FsetTableMode={FsetTableMode}
            selectedDropdownElement={selectedDropdownElement}
            language={language}
          >
            <VariationNavButtons
              data={memoizedData}
              language={language}
              markerdata={markerdata}
              mapClick="table"
              FsetTableMode={FsetTableMode}
            />
          </TableForecast>
        )}
      {/* was macht das */}

      {/* taweshighcharts */}
      {memoizedData.variation === 'tawes' &&
        markerdata &&
        markerdata?.data &&
        clickElement?.owner == 'tawes' && (
          <TawesHighcharts
            station={clickElement.data}
            language={language}
            pointsState={memoizedData.forecastPoints}
            // parameter={tawesSubmenuClick}
            parameterNumber={dayClick}
            // timestamps={markerdata.memoizedData.timestamps}
          />
        )}
      {/* texts: */}
      {memoizedData.textsforecast &&
        memoizedData.variation === 'weatherforecast' &&
        chartmode?.active !== memoizedData.variation + 'CHART' && (
          <WeatherForecastTextBlock
            forecasttext={memoizedData.textsforecast}
            serviceaddress={memoizedData.serviceaddress}
            className="block"
            selectedDropdownElement={selectedDropdownElement}
          />
        )}

      {/* external link information */}
      {memoizedData &&
        (chartmode?.active === memoizedData.variation + 'CHART' ||
          memoizedData.variation === 'tawes') &&
        language &&
        markerdata &&
        markerdata?.data && (
          <ExternalLinkButtons
            // chartmode={chartModeNot ? true : false}
            variation={memoizedData.variation}
            language={language}
            handleResetFromChart={handleResetFromChart}
            autIcon={autIcon}
          />
        )}
      {/* warnwidget */}
      {/* memoizedData.variation === 'warning' */}
      {memoizedData.variation === 'warning' &&
        chartmode?.active !== memoizedData.variation + 'CHART' &&
        language && <WarningBlock data={memoizedData} />}
    </div>
  );
};

export default View;
