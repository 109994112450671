import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Map, Layer, Source } from 'react-map-gl/maplibre';
import WeatherforecastMarkers from '../Forecast/WeatherforecastMarkers';

import 'maplibre-gl/dist/maplibre-gl.css';
import './map.css';

import { Icon } from '@plone/volto/components';
import aut from '../../../icons/austria/aut.svg';
import plusButton from '../../../icons/austria/Map_plus_White_16px.svg';
import minusButton from '../../../icons/austria/Map_minus.svg';

import { States } from '../../../constants/AutStates';
import style from '../Forecast/WeatherforecastMarkers.module.css';
import debounce from 'lodash/debounce'; // Add lodash for debouncing

import styledata from '../../Blocks/GeoSphereMap/style.json';
import { boundsAutEuWorld } from '../../../constants/boundsAutEuWorld';
import useMapState from '../../../hooks/useMapState';
import { setHash } from '../../utils/hashingHelpers';
import isMobileDevice from '../HelperFunctions/isMobile';

const shakemapStyle = {
  id: 'shakemapStyle',
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
  },
};

const transformRequest = (url, resourceType) => {
  // console.log(url, resourceType);
  const isRelative = !/^https?:\/\//.test(url);
  if (resourceType === 'Style') {
    return {
      url: url, // Customize URL if needed
    };
  }

  if (resourceType != 'Tile') {
    if (url.includes('admin_grenzen_oesterreich_2017')) {
      // console.log('now');
    } else if (url.includes('Hintergrundkarte_mit_Relief_von_Europa')) {
      // return url;
    } else {
      return { url };
    }
  }
  if (resourceType === 'Tile') {
    if (isRelative) {
      return {
        url:
          'https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/' +
          url,
      };
    }
    return { url };
    // return {
    //   url:
    //     'https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/' +
    //     url,
    // };
  }
};

function ZoomAUTButton({ onClick }) {
  return (
    <button
      className="zoom-button maplibregl-ctrl-compass"
      onClick={onClick}
      tets="3"
    >
      <Icon
        name={aut}
        size="14px"
        className="svg_icon"
        title="Österreich darstellen"
      />
    </button>
  );
}
function ZoomInButton({ onClick }) {
  return (
    <button className="zoom-button" onClick={onClick} tets="1">
      <Icon
        name={plusButton}
        size="14px"
        className="svg_icon"
        title="In die Karte Zoomen"
      />
    </button>
  );
}

function ZoomOutButton({ onClick }) {
  return (
    <button className="zoom-button" onClick={onClick} tets="2">
      <Icon name={minusButton} size="3px" title="Aus der Karte Zoomen" />
      {/* <div className="zoomButtonBox">-</div> */}
    </button>
  );
}

const SimpleMap = ({ data, day, stateAut = false, markerdata, children }) => {
  const variation = data?.variation;
  const mapRef = useRef();
  const { mapState, handleResize } = useMapState(data, variation, mapRef);

  const declutterMarkers = debounce(() => {
    // console.log('decl2222');
    const filteredMarkers = [];
    const occupiedSpaces = [];
    if (
      !mapRef.current ||
      !mapRef.current.getMap() ||
      !markerdata?.data?.features
    )
      return;
    const getZoom = mapRef.current.getZoom();

    let sortedFeatures = [...markerdata?.data.features];
    if (getZoom < 7)
      sortedFeatures = sortedFeatures?.filter(
        (i) => i.properties.priority === 0,
      );
    sortedFeatures?.forEach((marker, index) => {
      const coordinates = marker.geometry.coordinates;
      const titleLength = marker.properties.name.length;
      // console.log(titleLength);
      if (Array.isArray(coordinates) && coordinates.length === 2) {
        const [longitude, latitude] = coordinates;

        // Project the geographical coordinates to screen coordinates
        const screenCoords = mapRef.current.project([longitude, latitude]);
        const x = screenCoords.x;
        const y = screenCoords.y;
        // Define the marker's bounding box on the screen

        let markerWidth = titleLength * 22 + 25; // width +25 falls warnung

        if (
          (getZoom >= 9 || markerWidth < 100) &&
          marker.properties.priority !== 0
        ) {
          markerWidth = 180;
        }
        // else if (getZoom > 8.2) markerWidth = 200;
        // else markerWidth = 170;
        let markerHeight = 55; // height of marker
        // if (getZoom < 5.5) markerHeight = 40;
        const markerBox = {
          left: x - markerWidth / 2,
          right: x + markerWidth / 2,
          top: y - markerHeight / 2,
          bottom: y + markerHeight / 2,
        };

        // Check if the current marker overlaps with any already placed markers
        const isOverlapping = occupiedSpaces.some((occupiedBox) => {
          return !(
            markerBox.right < occupiedBox.left ||
            markerBox.left > occupiedBox.right ||
            markerBox.bottom < occupiedBox.top ||
            markerBox.top > occupiedBox.bottom
          );
        });

        // If not overlapping, add it to the filtered markers and mark the space as occupied
        if (!isOverlapping) {
          filteredMarkers.push(marker);
          occupiedSpaces.push(markerBox);
        }
      }
    });

    setFilterMarker(filteredMarkers);
  }, 500); // Debounce time (300 ms)

  const shakeMap = useSelector(
    // (state) => state.geospheredataplatformdata?[data.servicename] ? state.geospheredataplatformdata?[data.servicename].data : {}
    (state) =>
      state.geospheredataplatformdata
        ? state.geospheredataplatformdata[data.shakemap]
        : {},
  );

  const [filterMarker, setFilterMarker] = useState();

  // mounted state

  useEffect(() => {
    if (States && mapRef.current && typeof stateAut === 'number') {
      const stateBounds = States.find((i) => i.id == stateAut).borders;
      mapRef.current.fitBounds(stateBounds);
    }
  }, [States, stateAut]);

  useEffect(() => {
    if (variation === 'beben' && day >= 0 && mapRef?.current) {
      // missbrauche day - als info bei den beben ob ö, eu, welt ausgewählt wird
      const stateBounds = boundsAutEuWorld.find((i) => i.id == day).borders;
      mapRef.current.fitBounds(stateBounds, { animate: true });
    }
  }, [day]);

  // handel click on weatherforcase when station is selected on map
  // const [stationIdState, setStationIdState] = useState(false);
  const handleWeatherforecastType = (latlon, title, point_id = null) => {
    // setStationIdState(stationid);
    setHash('tab', 'chart');
    setHash('point_id', point_id);
    // window.location.hash = `tab=chart${point_id}`;
  };

  // let hoveredStateId; // ensure it gets reset
  const onMapLoad = useCallback((event) => {
    //setWheelZoomRate
    if (mapRef.current) {
      const map = mapRef.current.getMap(); // Get the raw MapLibre GL JS instance
      map.scrollZoom.setWheelZoomRate(0.5); // Adjust the zoom rate
      map.touchZoomRotate.disableRotation(); // disable rotate with fingers
      // map.scrollZoom.setZoomRate(0.01);

      if (variation == 'beben' && mapRef.current) {
        const stateBounds = boundsAutEuWorld.find((i) => i.id == day).borders;
        mapRef.current.fitBounds(stateBounds);
      }

      if (variation === 'weatherforecast' || variation === 'tawes') {
        //damit er sich merkt wo er hinzoomen soll, nur notwenig bei hauptstätden
        const setRightBounds = () => {
          if (
            States &&
            mapRef.current &&
            typeof stateAut === 'number' &&
            stateAut > 0
          ) {
            const stateBounds = States.find((i) => i.id == stateAut).borders;
            mapRef.current.fitBounds(stateBounds);
          }
        };
        handleResize();
        setTimeout(() => setRightBounds(), 1000); // Call once on mount
      }
      if (variation === 'weatherforecast') {
        declutterMarkers();
        mapRef.current?.on('moveend', declutterMarkers);
      }
    }

    mapRef.current.on('zoomend', (e) => {
      let markers = document.querySelectorAll(
        `.${style.label_weatherforecast_5days}`,
      );
      markers.forEach(function (marker) {
        marker.style.display = 'none';
        marker.style.display = 'flex';
      });
    });
  }, []);

  const zoomClickHandler = (el) => {
    if (!mapRef.current) return;
    // const zoom = data?.zoom ? data.zoom : 6;
    if (el) {
      mapRef.current.flyTo({
        zoom: mapRef.current.getZoom() + el,
        duration: 500,
      });
    } else {
      const bounds = [
        [7.82319510044934, 45.91189741860944],
        [20.204786897324055, 49.602615543845076],
      ];
      mapRef.current.fitBounds(bounds);
    }

    // handleResize(); // use zoomhandler when resizing but not for window resize
  };

  const mapStyle = data?.styleaddress?.includes('json')
    ? { ...styledata }
    : {
        version: 8,
        sources: {
          'arcgis-tiles': {
            type: 'raster',
            tiles: [data.styleaddress],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: 'arcgis-layer',
            type: 'raster',
            source: 'arcgis-tiles',
          },
        ],
      };

  return (
    <div className="weatherforecast_simplemap">
      <span
        id={variation + '-map'}
        style={{ display: 'none' }}
        data-info={variation}
      >
        {'Eine Karte die ' + variation + ' Informationen zeigt'}
      </span>
      {mapRef && (
        <div className="zoom-controls">
          <div className="zoom-controls-ctrl">
            <ZoomInButton onClick={() => zoomClickHandler(+1)} />
            <ZoomOutButton onClick={() => zoomClickHandler(-1)} />
            <ZoomAUTButton onClick={() => zoomClickHandler()} />
          </div>
        </div>
      )}
      <Map
        key={JSON.stringify(mapState)}
        ref={mapRef}
        initialViewState={mapState}
        style={{ height: isMobileDevice() ? 400 : 700 }} /* width: 800*/
        declutter={true}
        clusterRadius={3000}
        clusterMaxZoom={16}
        mapStyle={mapStyle}
        transformRequest={transformRequest}
        onLoad={onMapLoad}
      >
        {shakeMap?.data && typeof shakeMap.data.map === 'string' && (
          <Source
            id="shakeMap"
            type="geojson"
            data={JSON.parse(shakeMap.data.map)}
          >
            <Layer {...shakemapStyle} />
          </Source>
        )}
        {shakeMap?.data && typeof shakeMap.data.map === 'object' && (
          <Source id="shakeMap" type="geojson" data={shakeMap.data.map}>
            <Layer {...shakemapStyle} />
          </Source>
        )}

        {variation === 'weatherforecast' && filterMarker && (
          <WeatherforecastMarkers
            data={data}
            onWeatherforecastHandler={handleWeatherforecastType}
            markerdata={filterMarker}
            day={day}
          />
        )}
        {children}
      </Map>
    </div>
  );
};

export default SimpleMap;
