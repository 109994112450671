import React, { memo } from 'react';
import ControlPanelEarthquake from '../Blocks/GeoSphereMap/ControlPanelEarthquake';
import ControlPanelSubTawes from '../Blocks/GeoSphereMap/ControlPanelSubTawes';
import ControlPanelWeatherforecast from '../Blocks/GeoSphereMap/ControlPanelWeatherforecast';

interface ControlPanelsProps {
  markerdata?: {
    data: unknown;
  };
  data: {
    variation: string;
  };
  onButtonClick: () => void;
  isToday?: string;
  numberOfDays?: number;
  parameter?: number;
  tableActive?: string;
  language: string;
}
const ControlPanels: React.FC<ControlPanelsProps> = memo(
  ({
    data,
    markerdata,
    onButtonClick,
    isToday,
    numberOfDays,
    parameter,
    tableActive,
    language,
  }) => {
    if (markerdata?.data) {
      switch (data.variation) {
        case 'weatherforecast':
          return (
            <ControlPanelWeatherforecast
              numberOfDays={numberOfDays}
              onButtonClick={onButtonClick}
              selectedDayParent={parameter}
              isToday={isToday}
              language={language}
              referenceTime={markerdata.data.reference_time}
            />
          );
        case 'beben':
          return (
            <ControlPanelEarthquake
              onButtonClick={onButtonClick}
              language={language}
            />
          );
        case 'tawes':
          return (
            <ControlPanelSubTawes
              onButtonClick={onButtonClick}
              parameter={parameter}
              language={language}
              tableActive={tableActive}
            />
          );
        default:
          return null;
      }
    }
  },
);

export default ControlPanels;
