import React, { useState, memo } from 'react';
import WeatherForecastText from './WeatherForecastText';

const WeatherForecastTexts = memo(
  ({ serviceaddress, forecasttext, selectedDropdownElement }) => {
    return (
      <div className="WeatherForecastTexts">
        <WeatherForecastText
          forecasttext={forecasttext}
          // serviceaddress={serviceaddress}
          selName={selectedDropdownElement}
        />
      </div>
    );
  },
);

export default WeatherForecastTexts;
