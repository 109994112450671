export const navItemTawes = [
  {
    key: 0,
    name: 'Temperatur',
    nameEn: 'Temperature',
    par: [
      { name: 'Momentanwert', par: 'TL' },
      // { name: 'Minimum Heute', par: 'TLMIN' },
      // { name: 'Maximum Heute', par: 'TLMAX' },
    ],
    apis: [
      // { 'tawes-v1-10min': ['TL,TLMIN,TLMAX'] },
      // { 'klima-v2-10min': ['tl,tlmin,tlmax'] },
      { 'tawes-v1-10min': 'TL' },
      { 'klima-v2-10min': 'tl' },
      { 'klima-v2-1h': 'tl' },
      { 'klima-v2-1d': ['tl_mittel,tlmin,tlmax'] },
      { 'klima-v2-1m': ['tl_mittel,tlmin,tlmax'] },
    ],
    type: 'spline',
    color: '#f00', //rot
    color2: '#e50000', //rot
    color3: '#cc0000',
    unit: '°C',
    multiplicator: 1,
  },
  {
    key: 1,
    name: 'Niederschlag',
    nameEn: 'Precipitation',
    par: [
      { name: '10-Min.-Summe', par: 'RR' },
      { name: 'Stundensumme', par: 'RR' },
      { name: 'Tagessumme', par: 'RR' },
      // { name: '24 h Summe', par: 'RR' },
      // { name: '48 h Summe', par: 'RR' },
      // { name: '72 h Summe', par: 'RR' },
    ],
    apis: [
      { 'tawes-v1-10min': 'RR' },
      { 'klima-v2-10min': 'rr' },
      { 'klima-v2-1h': 'rr' },
      { 'klima-v2-1d': 'rr' },
      { 'klima-v2-1m': 'rr' },
    ],
    type: 'column',
    color: '#0000FF', //blau
    color2: '#0000b2',
    unit: 'mm/10min',
    multiplicator: 1,
  },
  {
    key: 2,
    name: 'Wind',
    nameEn: 'Wind',
    par: [
      { name: 'Windgeschwindigkeit', par: 'FFAM' },
      { name: 'Böenspitze', par: 'FFX' },
      // { name: 'Böe 6 h Maximum', par: 'FFX' },
    ],
    apis: [
      { 'tawes-v1-10min': ['FFAM,FFX'] }, // ffx
      { 'klima-v2-10min': ['ffam,ffx'] }, // ffx
      { 'klima-v2-1h': ['ff,ffx'] }, // ffx
      { 'klima-v2-1d': ['vv_mittel,ffx'] }, // ffx
      { 'klima-v2-1m': 'vv_mittel' }, // keine windspitzen!
    ],
    type: 'spline',
    color: '#0000FF', //blau
    unit: 'km/h',
    multiplicator: 3.6,
  },
  {
    key: 3,
    name: 'Sonne',

    nameEn: 'Sun',
    par: [
      { name: '10-Min.-Summe', par: 'SO' },
      // { name: 'Summe heute', par: 'SO' },
      // { name: 'Summe gestern', par: 'SO' },
    ],
    apis: [
      { 'tawes-v1-10min': 'SO' },
      { 'klima-v2-10min': 'so' },
      { 'klima-v2-1h': 'so_h' },
      { 'klima-v2-1d': 'so_h' },
      { 'klima-v2-1m': 'so_h' },
    ],
    // type: 'spline',
    type: 'column',
    color: '#ffc41d',
    unit: 'h', //oder h
    unitS: 's',
    multiplicator: 1,
  },
  {
    key: 4,
    name: 'Feuchte',
    nameEn: 'Humidity',
    par: [
      { name: '10-Min.-Summe', par: 'RFAM' },
      // { name: 'Summe heute', par: 'SO' },
      // { name: 'Summe gestern', par: 'SO' },
    ],
    apis: [
      { 'tawes-v1-10min': 'RFAM' },
      { 'klima-v2-10min': 'rf' },
      { 'klima-v2-1h': 'rf' },
      { 'klima-v2-1d': 'rf_mittel' },
      { 'klima-v2-1m': 'rf_mittel' },
    ],
    type: 'spline',
    color: '#ffc41d',
    unit: '%',
    multiplicator: 1,
  },

  // {
  //   key: 5,
  //   name: 'Schnee',
  //   par: [
  //     { name: 'Schneehöhe', par: 'SH' },
  //     { name: 'Neuschnee', par: 'SH' },
  //     { name: 'Summe 2 Tage', par: 'SH' },
  //     { name: 'Summe 3 Tage', par: 'SH' },
  //   ],
  // },
  {
    key: 5,
    name: 'Druck',
    nameEn: 'Pressure',
    par: [
      { name: '10-Min.-Summe', par: 'PRED' },
      // { name: 'Summe heute', par: 'SO' },
      // { name: 'Summe gestern', par: 'SO' },
    ],
    apis: [
      { 'tawes-v1-10min': 'PRED' },
      { 'klima-v2-10min': 'p' },
      { 'klima-v2-1h': 'p' },
      { 'klima-v2-1d': 'p_mittel' },
      { 'klima-v2-1m': 'p' },
    ],
    type: 'spline',
    color: '#2d6752',
    unit: 'hPa',
    multiplicator: 1,
  },
];

export const mapping = [
  {
    par: 'TL',
    nameDe: 'Temperatur',
    nameEn: 'Termperature',
    nameSort: 'TL',
    unit: '[°C]',
  },
  {
    par: 'RR',
    nameDe: 'Niederschlag',
    nameEn: 'Precipitation',
    nameSort: 'RR',
    unit: '[mm/10min]',
  },
  {
    par: 'FFAM',
    nameDe: 'Windgeschwindigkeit',
    nameEn: 'Wind',
    nameSort: 'FFAM',
    unit: '[km/h]',
  },
  {
    par: 'DD',
    nameDe: 'Windrichtung',
    nameEn: 'Winddirection',
    nameSort: 'DD',
    unit: '[°]',
  },
  {
    par: 'FFX',
    nameDe: 'Windspitzen',
    nameEn: 'Gust',
    nameSort: 'FFX',
    unit: '[km/h]',
  },
  {
    par: 'DDX',
    nameDe: 'Windspitzenrichtung',
    nameEn: 'Gustsdirection',
    nameSort: 'DDX',
    unit: '[mm/10min]',
  },

  {
    par: 'SO',
    nameDe: 'Sonne',
    nameEn: 'Sun',
    nameSort: 'SO',
    unit: '[sec]',
  },
  {
    par: 'RFAM',
    nameDe: 'Feuchte',
    nameEn: 'Humidity',
    nameSort: 'RFAM',
    unit: '[%]',
  },
  {
    par: 'PRED',
    nameDe: 'Druck',
    nameEn: 'Pressure',
    nameSort: 'PRED',
    unit: '[hPa]',
  },
];
