import React from 'react';
import moment from 'moment';
import styles from './TawesHighcharts.module.css';
// import { navItemTawes } from './data'; // Import the necessary data
import { navItemTawes } from '../../../constants/TawesHelper';
import { pointOrComma } from '../../utils/pointOrComma';

const TawesHighchartsHeader = ({
  language,
  parameter,
  parameterNumber,
  station,
  mappedPoint,
}) => {
  return (
    <div className={styles.measurement}>
      <div className={styles.seperaterItem}>
        <div className={styles.measurement__value}>
          {language === 'de' ? 'Aktueller Messwert' : 'Current measured value'}{' '}
          <div className={styles.ParamaterLabel}>
            [
            {language === 'de'
              ? navItemTawes[Number(parameterNumber)].name
              : navItemTawes[Number(parameterNumber)].nameEn}
            ]
          </div>
          :{' '}
          {parameter === 'FFAM'
            ? pointOrComma(
                (station.properties.parameters[parameter].data * 3.6).toFixed(
                  2,
                ),
                language,
              )
            : pointOrComma(
                station.properties.parameters[parameter].data,
                language,
              )}
          {parameter === 'SO'
            ? navItemTawes[Number(parameterNumber)]['unitS']
            : navItemTawes[Number(parameterNumber)]['unit']}{' '}
        </div>
        <div className={styles.flexTimeLine}>
          <div className={styles.slash}> | </div>
          <span className={styles.measurement__info}>
            {moment(station.properties.timestep).format('ddd, DD-MM-YYYY')}
          </span>
          <div className={styles.slash}> | </div>
        </div>
      </div>
      <div className={styles.seperaterItem}>
        <span className={styles.measurement__info}>
          {moment(station.properties.timestep).format('HH:mm')}
        </span>
        <div className={styles.slash}> | </div>
        <span>
          {mappedPoint.elevation}m {language === 'de' ? 'Seehöhe' : 'Altitude'}
        </span>
      </div>
    </div>
  );
};

export default TawesHighchartsHeader;
