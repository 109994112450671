import React, { useState, memo } from 'react';

import ButtonNoHtmlatt from '../../Components/ui/ButtonNoHtmlatt';

// const getDayLabel = (day = 0) => {
// switch (day) {
//   case 0:
//     return 'Heute';
//   case 1:
//     return 'letzten 7 Tage';
//   case 2:
//     return 'letztes Monat';

//   default:
//     break;
// }
// };
const MENU = [
  { name: 'Österreich', nameEn: 'Austria' },
  { name: 'Europa', nameEn: 'Europe' },
  { name: 'Welt', nameEn: 'World' },
];

const ControlPanelEarthquake = memo(({ day = 0, onButtonClick, language }) => {
  // console.log('nav erdbeben');
  const [selectedDay, setSelectedDay] = useState(day);

  const changeSelectedDay = (e, s) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedDay(Number(s));
  };
  // const nextDays = getNextNDays(numberOfDays);
  return (
    <div className="control-panel weatherforecast_navi weatherforecast_naviGrid4">
      <div>
        {MENU.map((selectableDay, index) => {
          return (
            <ButtonNoHtmlatt
              variant="primary"
              onClick={(e) => {
                changeSelectedDay(e, index);
                onButtonClick(index);
              }}
              key={index}
              active={index === selectedDay}
              label={
                language == 'de' ? selectableDay.name : selectableDay.nameEn
              }
            ></ButtonNoHtmlatt>
          );
        })}
      </div>
    </div>
  );
});

export default ControlPanelEarthquake;
