import React, { useState, useEffect, memo } from 'react';
import ButtonNoHtmlatt from '../../Components/ui/ButtonNoHtmlatt';
import moment from 'moment';
import { isToday } from '../../utils/isToday.js';
moment.locale('de');

const getNextNDays = (n, Today, language) => {
  const calcDate = new Date(Today); // Get today's date
  const dayNames = ['Heute', 'Morgen', 'Übermorgen']; // Names for today, tomorrow, and the day after tomorrow
  const dayNameE = ['Today', 'Tomorrow'];
  const dates = [];

  for (let i = 0; i < n; i++) {
    // "2024-07-31"
    const date = new Date(calcDate); // Create a new date object for each day
    date.setDate(calcDate.getDate() + i); // Set the date to the next day
    const date2 = moment(calcDate, 'YYYY-MM-DD').add(i, 'day');

    if (!isToday(Today)) {
      dates.push({
        name: moment(date2).format('ddd DD.MM'),
        shortName: moment(date2).format('DD.MM'),
        date,
      });
    } else if (i === 0) {
      dates.push({
        name: language == 'de' ? dayNames[i] : dayNameE[i],
        date,
        shortName: language == 'de' ? dayNames[i] : dayNameE[i],
      });
    } else if (i < 2) {
      dates.push({
        name: language == 'de' ? dayNames[i] : dayNameE[i],
        date,
        shortName:
          language == 'de'
            ? date.toLocaleDateString('de-DE', { weekday: 'long' }).slice(0, 2)
            : date.toLocaleDateString('en-EN', { weekday: 'long' }).slice(0, 2),
      });
    } else {
      dates.push({
        name:
          language == 'de'
            ? date.toLocaleDateString('de-DE', { weekday: 'long' })
            : date.toLocaleDateString('en-EN', { weekday: 'long' }),

        shortName:
          language == 'de'
            ? date.toLocaleDateString('de-DE', { weekday: 'long' }).slice(0, 2)
            : date.toLocaleDateString('en-EN', { weekday: 'long' }).slice(0, 2),
        date,
      }); // Push the date and its name into the array
    }
  }

  return dates;
};

const handleResize = (setShortText) => {
  if (window.innerWidth < 1250) {
    setShortText(true);
  } else {
    setShortText(false);
  }
};

const ControlPanel = memo(
  ({
    numberOfDays = 3,
    onButtonClick,
    isToday = true,
    referenceTime,
    language,
    selectedDayParent,
  }) => {
    const [shortText, setShortText] = useState(false);

    useEffect(() => {
      // Set initial text based on the initial window size
      handleResize(setShortText);

      // Attach the event listener
      window.addEventListener('resize', () => handleResize(setShortText));
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', () => handleResize(setShortText));
      };
    }, []);

    // const changeSelectedDay = (e, s) => {
    //   e.stopPropagation();
    //   e.preventDefault();

    //   setSelectedDay(Number(s));
    // };
    const nextDays = getNextNDays(numberOfDays, isToday, language);
    return (
      <div className="control-panel weatherforecast_navi">
        <div className="weatherforecast_navi_days">
          {nextDays.map((selectableDay, index) => {
            return (
              <ButtonNoHtmlatt
                variant="primary"
                onClick={(e) => {
                  // changeSelectedDay(e, index);
                  onButtonClick(index);
                }}
                key={selectableDay.name}
                active={index === selectedDayParent}
                label={shortText ? selectableDay.shortName : selectableDay.name}
              ></ButtonNoHtmlatt>
            );
          })}
        </div>

        <div className="navbar-right navbar-right-long">
          {moment(referenceTime, 'YYYY-MM-DDTHH:mm:ssZ').format(
            'dddd, Do MMMM YYYY',
          )}
          <div className="slashColorMaringRL1"> | </div>
          {moment(referenceTime, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm')}
          {language == 'de' ? 'Uhr' : null}
        </div>
        <div className="navbar-right navbar-right-short">
          {moment(referenceTime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD.MM.YYYY')}
          <div className="slashColorMaringRL1"> | </div>
          {moment(referenceTime, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm')}
          {language == 'de' ? 'Uhr' : null}
        </div>
      </div>
    );
  },
);

export default ControlPanel;
